import _ from "lodash";

export     const createQueryFromObject = (query) => {
    let queryString = "?";
    for (const key in query) {
        if (query.hasOwnProperty(key)) {
            const element = query[key];
            queryString += `${key}=${element}&`;
        };
    };
    if (queryString.endsWith("&")) {
        queryString = queryString.slice(0, -1);
    };

    if (queryString === "?") {
        queryString = "";
    };

    if (_.isEmpty(queryString)) {
        throw new Error("No query string created");
    };
    return queryString;
};
import _ from "lodash"

//Status: {/* "completed", "open", "in progress", "on hold", reclamation_customer, reclamation_hpm, internalCheck, canceled */}
export function statusHelper(status) {

    if (status === undefined || status === null || status === "" || _.isString(status) === false) {
        return "Unbekannter Status"
    }

    switch (status) {
        case "completed":
            return "Abgeschlossen"
        case "open":
            return "Offen"
        case "in progress":
            return "In Bearbeitung"
        case "on hold":
            return "Wartend / on hold"
        case "reclamation_customer":
            return "Reklamation Kunde"
        case "reclamation_hpm":
            return "Reklamation HPM"
        case "internalCheck":
            return "Interne Prüfung"
        case "canceled":
            return "Storniert"
        default:
            return "Unbekannter Status"
    };
};

export const statusArray = [
    "completed",
    "open",
    "in progress",
    "on hold",
    "reclamation_customer",
    "reclamation_hpm",
    "internalCheck",
    "canceled",
    "confirmed",
];

export function statusColorHelper(status) {
    
        if (status === undefined || status === null || status === "" || _.isString(status) === false) {
            return "grey"
        }
    
        switch (status) {
            case "completed":
                return "green"
            case "open":
                return "red"
            case "in progress":
                return "orange"
            case "on hold":
                return "blue"
            case "reclamation_customer":
                return "purple"
            case "reclamation_hpm":
                return "purple"
            case "internalCheck":
                return "purple"
            case "canceled":
                return "grey"
            default:
                return "grey"
        };
    }

    export function statusAxiosReturnColorHelper(status) {

        switch (status) {
            case true:
                return {badgeColorAntd: "success", badgeText: "OK"}
            case null:
                return {badgeColorAntd: "error", badgeText: "Fehler"}
            case false:
                return {badgeColorAntd: "error", badgeText: "Fehler"}
            case undefined:
                return {badgeColorAntd: "warning", badgeText: "N/V"}
            default:
                return {badgeColorAntd: "warning", badgeText: "N/V"}
        };
    }
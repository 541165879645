import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Typography } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../firebase";

export function LoginForm() {
  const { login, loading } = useAuth();

    const navigate = useNavigate();
  
    const handleLogin = async () => {
      try {
        await login(email, password);
        navigate("/");
      } catch (error) {
        console.log(error);
      }
    };
  
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
  
    return (
      <Row justify="center" align="middle" style={{ minHeight: '0vh' }}>
        <Col xs={20} sm={16} md={12} lg={8}>
          <div style={{ textAlign: 'center'}}>
            <Typography.Title level={2}>Anmelden</Typography.Title>
            <Typography.Text type='secondary'>Bitte melden Sie sich an, um fortzufahren.</Typography.Text>
          </div>
          <Form name="login-form" onFinish={() => (console.log("finish"))}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Bitte geben Sie Ihre Email ein.' }]}
            >
              <Input
                prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Email"
                size='large'
                onChange={(e) => {setEmail(e.target.value)}}
                autoFocus
                autoComplete='email'
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Bitte geben Sie Ihr Passwort ein.' }]}
            >
              <Input
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Passwort"
                size='large'
                onChange={(e) => {setPassword(e.target.value)}}
                autoComplete='current-password'
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" size='large' block id='sign-in-button'
              style={{
                backgroundColor: '#DA3E52',
              }}
              onClick={handleLogin}
              loading={loading}
              >
                Anmelden
              </Button>
            </Form.Item>
            <Form.Item>
            <div id='recaptcha-container'></div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  };
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword, signOut, multiFactor, onAuthStateChanged, sendEmailVerification, RecaptchaVerifier, PhoneAuthProvider, setPersistence, browserSessionPersistence } from "firebase/auth"
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import store, { resetStore } from './redux/store';
import { updateFirebaseUser, updateFirId } from './redux/userSlices';
import { fetchCurrentUserV1, updateUserCustomClaims } from './modules/fetchUsers';
import { getPerformance } from "firebase/performance";
import { displayErrorNotification } from './utils/messageServices/notificationService';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { displayWarningModal } from './utils/messageServices/modalService';
import { invokeSession } from './config/session/session';
import { useUpdate } from 'ahooks';
import { addListenerTo, addListenerToPath, unsubscribeFrom } from './listener/firebaseSnapshotListener';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const user = auth.currentUser;
const storage = getStorage(app, process.env.REACT_APP_FIREBASE_STORAGE_BUCKET);
const db = getFirestore(app);
const analytics = process.env.NODE_ENV === 'production' ? getAnalytics(app) : null;

const locToke2 = process.env.REACT_APP_APPCHECK_KEY;

initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(locToke2),
  isTokenAutoRefreshEnabled: true
});


const loginUser = async (email, password) => {
  const appVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
    'size': 'visible',
    'callback': (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      console.log("recaptcha solved")
    },
    'expired-callback': () => {
      // Response expired. Ask user to solve reCAPTCHA again.
      console.log("recaptcha expired")
    }
  });
  setPersistence(auth, browserSessionPersistence)
  signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // Signed in 
      store.dispatch(updateFirebaseUser(await fetchCurrentUserV1(userCredential.user)))
      const user = userCredential.user;
      if (user.emailVerified === false) {
        await sendEmailVerification(auth.currentUser);
      }
      return (true);
    })
    .catch((error) => {
      const errorCode = error.code;
      console.log(errorCode);
      if (errorCode === "auth/wrong-password") {
        console.log("Falsche kombination aus Passwort und Email");
        displayErrorNotification("Falsches Passwort");
      } else if (errorCode === "auth/user-not-found") {
        displayErrorNotification("Falsche kombination aus Passwort und Email");
      } else if (errorCode === "auth/firebase-app-check-token-is-invalid.") {
        displayErrorNotification("Login not possible low S-Rate. Please contact support.");
      } else {
        displayErrorNotification("Login not possible. Contact support.");
      }
      const errorMessage = error.message;
      console.log(errorMessage);
      return (false);
    });
};

const logOutUser = async () => {
  await signOut(auth).then(() => {
    // Sign-out successful.
    console.log("isgn out")
  }).catch((error) => {
    // An error happened.
    console.log(error)
  });
};

const userIsAuthenticated = () => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      return true;
    } else {
      // User is signed out
      return false;
    }
  });
};

function isLoggedIn() {
  const user = auth.currentUser;
  if (user) {
    // Der Nutzer ist eingeloggt
    console.log("Der user ist eingeloggt.")
    return (true);
  } else {
    // Der Nutzer ist nicht eingeloggt
    console.log("Der user ist nicht eingeloggt.")
    return (false);
  };
};


// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);
perf.dataCollectionEnabled = true;

export const getCollectionData = async (collectionName) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));

    const documents = [];
    querySnapshot.forEach((doc) => {
      // Fügt jedes Dokument mit seiner ID und allen Feldern zum Array hinzu
      documents.push({
        id: doc.id,
        ...doc.data()
      });
    });

    return documents;
  } catch (error) {
    console.error("Fehler beim Abrufen der Dokumente:", error);
    return [];
  }
};


const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [role, setRole] = useState("employee");
  const [mongoUid, setMongoUid] = useState(null);
  const [claims, setClaims] = useState(null);
  const [authTime, setAuthTime] = useState(null);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [hasLoggedOut, setHasLoggedOut] = useState(false);
  const [firUid, setFirUid] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [modalShown, setModalShown] = useState(false);  // Neuer State für Modal-Status

  useEffect(() => {
    if (!user && !loading) {
      logout();
    };

    return () => {
      setHasLoggedOut(true);
    };
  }, [user]);


  useEffect(() => {
    let listener = null;
    if (!_.isEmpty(firUid)) {
      console.debug("User-listener added to:", `users/${firUid}`);
      listener = addListenerToPath(`users/${firUid}`, async (snapshot) => {
        console.debug("User data changed:", snapshot);
        try {
          // const doc = snapshot.data();
          // console.debug("User data:", doc);
          await getUserCustomClaims();
          await user.getIdToken(true);
        } catch (error) {
          console.error("Error while fetching user data", error);
        }
      });
    };

    return () => {
      if (listener) {
        unsubscribeFrom(listener);
      };
    };
  }, [firUid]);

  // Separate Error Handler
  const handleLoginError = (error) => {
    const errorMessages = {
      'auth/wrong-password': 'Falsches Passwort',
      'auth/user-not-found': 'Falsche Kombination aus Passwort und Email',
      'auth/firebase-app-check-token-is-invalid': 'Login nicht möglich (Sicherheitscheck fehlgeschlagen). Bitte Support kontaktieren.',
      'default': 'Login nicht möglich. Bitte Support kontaktieren.'
    };

    console.error('Login Error:', {
      code: error.code,
      message: error.message,
      timestamp: new Date().toISOString()
    });

    displayErrorNotification(errorMessages[error.code] || errorMessages.default);
    return false;
  };

  // Improved Login Function
  async function login(email, password) {
    try {
      // Input Validation
      if (!email?.trim() || !password?.trim()) {
        throw new Error('auth/invalid-input');
      }

      // Set Persistence
      await setPersistence(auth, browserSessionPersistence);

      // Sign In
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;

      // Update User in Store
      const currentUser = await fetchCurrentUserV1(user);
      store.dispatch(updateFirebaseUser(currentUser));
      store.dispatch(updateFirId(_.get(user, 'uid')));
      setFirUid(_.get(user, 'uid'));

      await getUserCustomClaims();
      await updateUserCustomClaims(user, (error) => {
        if (error) {
          throw error;
        }
      });

      // Handle Email Verification
      if (!user.emailVerified) {
        await sendEmailVerification(auth.currentUser);
      } else {
        setEmailVerified(true);
      };

      // Set Authentication State
      setIsAuthenticated(true);

      // Log successful login
      console.info('Login successful:', {
        uid: user.uid,
        email: user.email,
        timestamp: new Date().toISOString()
      });

      return true;

    } catch (error) {
      setIsAuthenticated(false);
      return handleLoginError(error);
    }
  }

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const getUserCustomClaims = async () => {
    try {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        const cleanedClaims = _.cloneDeep(idTokenResult.claims);
        delete cleanedClaims.firebase;
        setClaims(cleanedClaims);
        setFirUid(cleanedClaims?.user_id);
        setEmailVerified(cleanedClaims.email_verified);
        setAuthTime(idTokenResult?.authTime);
        setRole(cleanedClaims?.role);
        setSuperAdmin(cleanedClaims?.superAdmin);
        setMongoUid(cleanedClaims?.mongoId);

        if (!cleanedClaims?.mongoId && !modalShown) {
          setModalShown(true);
          displayWarningModal(
            "Benutzerprofil wird aktualisiert",
            "Dein Benutzerprofil wird jetzt automatisch aktualisiert. Dafür mussten wir dich kurzzeitig ausloggen. Bitte logge dich erneut ein."
          );
          
          await delay(2000);
          await logout();
        };

        return;
      } else {
        return null; //Hallo test
      }
    } catch (error) {
      logout();
    };
  };

  const logout = async (logoutParams) => {
    try {
      await signOut(auth);
      store.dispatch(resetStore());
      setIsAuthenticated(false);

      if (logoutParams) {
        window.location.href = logoutParams.returnTo;
      };
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const roleHierarchy = {
    administrator: ['administrator', 'projectLead', 'consultant', 'management', 'hr', 'viewer', 'accounting', 'project-assistance'],
    "project-assistance": ['projectLead', 'consultant', 'management', 'hr', 'viewer', 'accounting', 'project-assistance'],
    projectLead: ['consultant', 'management', 'hr', 'viewer', 'accounting', 'projectLead'],
    hr: ['viewer', 'accounting', 'hr'],
    accounting: ['viewer', 'accounting'],
    viewer: ['viewer'],
    user: ['user', 'employee'],
    employee: ['user', 'employee']
  };

  //Auswahl nur aus roleHierarchy
  const hasPermission = (reqMinimumPermission) => {
    //TODO: Implement role hierarchy
    if (!reqMinimumPermission) {
      return false;
    };

    const permission = reqMinimumPermission.toLowerCase();

    if (!roleHierarchy[role]) {
      return false;
    }

    // Überprüfen, ob die Benutzerrolle die erforderliche Berechtigung hat
    return roleHierarchy[role].includes(permission);
  };

  const disabledByPermission = (permission) => {
    return !hasPermission(permission);
  };

  // Token Refresh Logic hinzufügen
  useEffect(() => {
    if (!user) return;

    const refreshToken = setInterval(async () => {
      try {
        await user.getIdToken(true);
        await getUserCustomClaims();
      } catch (error) {
        console.error('Token refresh failed:', error);
      }
    }, 30 * 60 * 1000); // Alle 30 Minuten

    return () => clearInterval(refreshToken);
  }, [user]);

  //Effects
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      try {
        console.log("Auth state changed:", currentUser);
        setUser(currentUser);
        setIsAuthenticated(!!currentUser);
        if (currentUser) {
          await getUserCustomClaims();
          invokeSession();
        } else if (!loading && !hasLoggedOut) {
          await logout();
          setHasLoggedOut(true);
        }
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, [loading, hasLoggedOut]);


  return {
    user,
    loading,
    error,
    login,
    logout,
    role,
    claims,
    authTime,
    superAdmin,
    hasPermission,
    disabledByPermission,
    isAuthenticated,
    mongoUid,
    firUid,
    emailVerified,
    getUserCustomClaims
  };

};


export { auth, isLoggedIn, loginUser, logOutUser, userIsAuthenticated, user, app, storage, db, analytics, useAuth };
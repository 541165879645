import { setAPI } from "./api";
import { auth } from "../firebase";
import { axiosInstance as axios } from "../axios/axiosInstance";


const API_URL = setAPI();

export async function getDashboardVolumes() {
    try {
      return await axios.post(`${API_URL}/api/kpi/insights/default`, { data: null });
      //Es werden die umsätze Berechnet
    } catch (error) {
      console.log(error);
    };
  };

  export async function getKpiFromProject(projectId) {
    try {
      return await axios.post(`${API_URL}/api/kpi/insights/fromProject`, { projectId: projectId });
      //Es werden alle KPIs aus dem Projekt berechnet
    } catch (error) {
      console.log(error);
      return error;
    };
  };
import { v4_setAPI } from "../api";
import _ from "lodash";
import { displayErrorModal } from "../../utils/messageServices/modalService";
import { axiosInstance } from "../../axios/axiosInstance";

const API_URL = v4_setAPI();


export async function v4_get_kpi(_id, filter) {

    try {
        if (_.isEmpty(filter)) {
            throw new Error("Filter object is empty");
        };

        const filterString = `?${Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&')}`;
        const response = await axiosInstance.get(`${API_URL}/kpis/${_id}${filterString}`);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Laden der KPI", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};

export async function v4_get_org_kpi(filter) {
    
        try {
            if (_.isEmpty(filter)) {
                throw new Error("Filter object is empty");
            };
    
            const filterString = `?${Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&')}`;
            const response = await axiosInstance.get(`${API_URL}/kpis/org/kpi${filterString}`);
            return response.data;
        } catch (error) {
            console.error(error);
            displayErrorModal("Fehler beim Laden der KPI", error?.response?.data?.error, error?.response?.data?.stack)
            return null;
        };
}
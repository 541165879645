import { Button, Card, Checkbox, Divider, Form, Input, Modal, Select, Switch, Typography } from "antd";
import React, { useCallback, useEffect } from "react";
import { useEnvironment } from "../../../hooks/useEnvironment";
import { v4_project_addNewAccount, v4_project_addNewNotificationSetting, v4_project_getAddresses } from "../../../routing/v4_Routes/project.routes";

const { Item: FormItem } = Form;

export function NewNotificationSetting({ //Account = Kontakt
    callback,
    callbackOnCancel,
    showAsCard = true,
    edit = false,
    projectId = null,
    projectData = null
}) {

    const [visible, setVisible] = React.useState(false);
    const { loading } = useEnvironment();
    const [form] = Form.useForm();


    const onCancel = () => {
        setVisible(false);
        if (callbackOnCancel) {
            callbackOnCancel();
        };
    };

    const getProjectData = useCallback(async () => {
        if (projectData) {
            console.log(projectData);
        };
    }, [projectData]);

    const onSave = async () => {
        try {
            if (!projectId) {
                throw new Error("No project ID provided");
            };
            const values = await form.validateFields();
            await v4_project_addNewNotificationSetting(projectId, values);
        } catch (error) {
            console.error(error);   
        } finally {
            setVisible(false);
            if (callback) {
                callback();
            };
        };
    };

    useEffect(() => {
        getProjectData();
    }, [projectId]);

    return (<>
        <div>
            {showAsCard ? (
                <Card>
                    <Button type="link" onClick={() => {
                        setVisible(true);
                    }}>
                        Neue Benachrichtungseinstellung hinzufügen
                    </Button>
                </Card>
            ) : (
                <Button type="link" onClick={() => {
                    setVisible(true);
                }}>
                    Neue Benachrichtungseinstellung hinzufügen
                </Button>
            )}
        </div>

        <Modal
            title="Neue Benachrichtungseinstellung"
            open={visible}
            onCancel={onCancel}
            width={"60%"}
            closable={false}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Abbrechen 
                </Button>,
                <Button key="submit" type="primary" onClick={onSave}>
                    Speichern
                </Button>,
            ]}
            loading={loading}
            destroyOnClose
        >
            <div>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={async (values) => {
                        console.log(values);
                        setVisible(false);
                        if (callback) {
                            callback();
                        };
                    }}
                >
                    <FormItem
                        label="Name der Benachrichtigung"
                        name="name"
                        rules={[{ required: true, message: "Bitte geben Sie einen Namen an." }]}
                    >
                        <Input placeholder="z.B. Benachrichtung für Mitarbeiter XY" />
                    </FormItem>
                    <FormItem
                        label="Email"
                        name="targetEmail"
                        rules={[{ required: true, message: "Bitte geben Sie eine Email an." }]}
                    >
                        <Input placeholder="...@hpm.services" type="email" autoComplete="off" />
                    </FormItem>

                    <FormItem
                        label="Benachrichtigungstyp"
                        name="notificationType"
                        rules={[{ required: true, message: "Wählen Sie einen oder mehere Benachrichtigungstypen." }]}
                    >
                        <Select
                            placeholder="Benachrichtigungstyp"
                            mode="tags"
                            //// enum: ['invoice', 'delivery-note', 'order-confirmation', 'all', 'approvals', 'new-order', 'rejected-order', 'cancelled-order', 'completed-order', 'delivered-order', 'invoiced-order', 'partially-delivered-order'],
                            options={[
                                { value: "delivery-note", label: "Lieferschein" },
                                { value: "invoice", label: "Rechnung" },
                                { value: "order-confirmation", label: "Auftragsbestätigung" },
                                { value: "approval", label: "Freigabeanforderung" },
                                { value: "new-order", label: "Neuer Auftrag" },
                                { value: "rejected-order", label: "Abgelehnter Auftrag" },
                                { value: "cancelled-order", label: "Stornierter Auftrag" },
                                { value: "completed-order", label: "Abgeschlossener Auftrag" },
                                { value: "delivered-order", label: "Gelieferter Auftrag" },
                                { value: "invoiced-order", label: "Verrechneter Auftrag" },
                                { value: "partially-delivered-order", label: "Teilweise gelieferter Auftrag" },
                            ]}
                        />
                    </FormItem>
                </Form>
            </div>
        </Modal>

    </>)
}
import axios from "axios";
import { auth } from "../firebase";
import store from "../redux/store";

/**
 * @description Axios-Instanz für die Verwendung in der gesamten Anwendung
 * @type {AxiosInstance}
 * @package axios
 * @see https://axios-http.com/docs/instance
 */
export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    async (config) => {
        try {
            const _id = store.getState().userState?.mongoUser?._id;
            const token = await auth.currentUser.getIdToken();
            config.headers.Authorization = `Bearer ${token}`;
            config.headers.MongoUser = `${_id}`;
        } catch (error) {
            console.error("Error fetching token: ", error);
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
import { Button, Form, Modal } from "antd";
import React, { useEffect } from "react";
import { CreateDeliveryForm } from "../../forms/order/CreateDelivery.form";
import { v4_createNewDelivery } from "../../../routing/v4_Routes/order.routes";



export function NewDeliveryModal({
    initialDeliveryData,
    order,
    callback,
    open,
    onClose
}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);

    const onSubmit = async () => {
        setLoading(true);
            try {
                const formValues = await form.validateFields()
                await v4_createNewDelivery(order._id, formValues);
            } catch (error) {
                console.error(error);
                return;
            } finally {
                setLoading(false);
                form.resetFields();
                onClose();
            };
    };

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    useEffect(() => {

        return () => { //Handled, when the component is unmounted (closed)
            if (callback && typeof callback === "function") {
                if (open) {
                    callback();
                };
            };
        };
    }, [open]);

    return (
        <Modal
                title={`Lieferung erstellen für Bestellung ${order?.orderNumber}`}
                open={open}
                onCancel={onCancel}
                destroyOnClose
                width={"100%"}
                centered
                loading={loading}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        onClick={onSubmit}
                        disabled={loading}
                        loading={loading}
                    >
                        Lieferung erstellen
                    </Button>,
                    <Button
                        key="cancel"
                        onClick={() => onCancel()}
                        disabled={loading}
                    >
                        Abbrechen
                    </Button>
                ]}
            >
                <CreateDeliveryForm
                    form={form}
                    initialData={initialDeliveryData}
                    order={order}
                />
            </Modal>
    );
};
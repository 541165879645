import { Button, Col, Divider, Row, Table, Typography } from "antd";
import React, { useEffect } from "react";
import { NewOrderModal } from "../../modals/order/NewOrder.modal";
import { useOrders } from "../../../hooks/useOrders";
import { OrdersTable } from "../../tables/OrdersTable";
import Search from "antd/es/input/Search";



export function OrdersPage() {

    const { orders, loading, refresh } = useOrders();
    const [filteredOrders, setFilteredOrders] = React.useState(orders);

    const search = (value) => {
        const lowerCaseValue = value.toLowerCase().trim();
        if (!lowerCaseValue) {
            setFilteredOrders(orders);
        } else {
            const filteredData = orders.filter(item => {
                return Object.keys(item).some(key =>
                    typeof item[key] === "string" && item[key].toLowerCase().includes(lowerCaseValue)
                );
            });
            setFilteredOrders(filteredData);
        }
    };

    const onClear = () => {
        setFilteredOrders(orders);
    };

    useEffect(() => {
        onClear();
    }, []);

    useEffect(() => {
        setFilteredOrders(orders);
    }, [orders]);


    return (
        <div>
            <div>
                <Typography.Title level={2}>Aufträge</Typography.Title>
            </div>


            <div>
                <Divider
                    orientation="left"
                    style={{ color: "#333" }}
                >
                    <Typography.Text>Neuer Auftrag</Typography.Text>
                </Divider>

                <div style={{
                    alignContent: "left",
                    textAlign: "left",
                }}>
                    <Row
                        gutter={[16, 16]}
                    >
                        <Col span={6}>
                        <div>
                            <NewOrderModal showAsCard callback={() => refresh()} />
                        </div>
                        </Col>
                        <Col span={6}>
                        <div>
                            
                        </div>
                        </Col>
                    </Row>
                </div>
            </div>


            <div>
                <Divider
                    orientation="left"
                    style={{ color: "#333" }}
                >
                    <Typography.Text>Aufträge</Typography.Text>
                </Divider>

                <div style={{
                    alignContent: "left",
                    textAlign: "left",
                    marginBottom: "1rem",
                }}>
                    <Search
                        placeholder="Suchen..."
                        onSearch={value => search(value)}
                        enterButton="Suchen"
                        onReset={onClear}
                        size="large"
                        suffix={<Button size="small" type="link" onClick={onClear}>Zurücksetzen</Button>}
                    />
                </div>

                <div style={{
                    alignContent: "left",
                    textAlign: "left",
                }}>
                    <OrdersTable
                        data={filteredOrders}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
};
// messageService.js
/**
 * @param {string} title
 * @param {string} content
 * @description This function is used to display an error message in a modal.
 */
import { Alert, Button, Modal, Space, Typography } from 'antd';

const { Title, Text } = Typography;

export const displayErrorModal = (title, content, stack = null) => {
  Modal.error({
    title: "Oh nooo! 😱 - " + title,
    content: <div>
  <Space direction="vertical">
  <Alert message="Hinweis" description={
    <Text>Es scheint so als wäre ein Fehler aufgetreten, diese Fehlermeldung enthält eventuell auch details wie du den Fehler selbst beheben kannst. Sofern du Unterstützung benötigtst, haben wir dir alle Informationen aufgelistet die du den Administrator:innen geben musst.</Text>
  } type="info" showIcon />
  <Alert message="Fehlermeldung" description={<Text>Deine Fehlermeldung: {content}</Text>} type="error" showIcon />
  {stack && <Alert message="Stack Trace (Alle Informationen die du Administratoren geben musst)" description={<Text copyable>{stack}</Text>} type="error" showIcon />}
  </Space>
    </div>,
    getContainer: () => document.getElementById('rootContainer'),
    style: {
      top: '20px',
      width: '80%',
    },
    width: '80%',
    footer: <div style={{
      alignItems: "right",
      justifyContent: "right",
      textAlign: "right",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginTop: "10px",
    }}>
      <Space direction="horizontal">
        <Button type="primary" disabled onClick={() => {}}>Fehlermeldung versenden</Button>
        {/* Modal schließen */}
        <Button type="default" onClick={() => Modal.destroyAll()}>Schließen</Button>
      </Space>
    </div>
  });
};

/**
 * @param {string} title
 * @param {HTML} content
 * //Type: Error
 * @param {Error} stack
 * @description This function is used to display a blank modal.
 */
export const displayBlankModal = (title, content, stack = null) => {
  Modal.error({
    title: title,
    content: <div>
       {content}
    </div>,
    getContainer: () => document.getElementById('rootContainer'),
    style: {
      top: '20px',
      width: '80%',
    },
    width: '80%',
    footer: <div style={{
      alignItems: "right",
      justifyContent: "right",
      textAlign: "right",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginTop: "10px",
    }}>
      <Space direction="horizontal">
        <Button type="default" onClick={() => Modal.destroyAll()}>Schließen</Button>
      </Space>
    </div>
  });
};

export const displaySuccessModal = (title, content) => {
  Modal.success({
    title: title,
    content: content,
    getContainer: () => document.getElementById('rootContainer'),
  });
}

export const displayWarningModal = (title, content, callback = null) => {
  if (callback) {
    Modal.warning({
      title: title,
      content: content,
      getContainer: () => document.getElementById('rootContainer'),
      onOk: async () => {
        await callback();
      },
    });
  } else {
    Modal.warning({
      title: title,
      content: content,
      getContainer: () => document.getElementById('rootContainer'),
    });
  }
}

export const displayInfoModal = (title, content, width = "50%") => {
  Modal.info({
    title: title,
    content: content,
    getContainer: () => document.getElementById('rootContainer'),
    width: width,
    style: {
      top: '20px',
      width: width,
    }
  });
}
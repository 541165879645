import { Button, Card, Checkbox, Divider, Form, Input, Modal, Select, Switch, Typography } from "antd";
import React, { useCallback, useEffect } from "react";
import { useEnvironment } from "../../../hooks/useEnvironment";
import { v4_project_addNewAccount, v4_project_getAddresses } from "../../../routing/v4_Routes/project.routes";

const { Item: FormItem } = Form;

const accountTypeOptions = [
    { value: "Sonstiger Kundenansprechpartner", label: "Sonstiger Kundenansprechpartner" },
    { value: "Logisitk (Kunde)", label: "Logistik (Kunde)" },
    { value: "Projektleiter (Kunde)", label: "Projektleiter (Kunde)" },
    { value: "Mitarbeiter (Kunde)", label: "Mitarbeiter (Kunde)" },
    { value: "Einkauf (Kunde)", label: "Einkauf (Kunde)" },
    { value: "Sonstiges (Kunde)", label: "Sonstiges (Kunde)" },
];

export function NewAccountModal({ //Account = Kontakt
    callback,
    callbackOnCancel,
    showAsCard = true,
    edit = false,
    projectId = null,
}) {

    const [visible, setVisible] = React.useState(false);
    const { project, loading } = useEnvironment();
    const [form] = Form.useForm();
    const [addresses, setAddresses] = React.useState([]);
    const [loadingAddresses, setLoadingAddresses] = React.useState(false);


    const onCancel = () => {
        setVisible(false);
        if (callbackOnCancel) {
            callbackOnCancel();
        };
    };

    const getAddresses = useCallback(async () => {
        if (projectId) {
            const addresses = await v4_project_getAddresses(projectId);
            setAddresses(addresses);
        };
    }, [projectId]);

    const onSave = async () => {
        setLoadingAddresses(true);
        try {
            if (!projectId) {
                throw new Error("No project ID provided");
            };
            const values = await form.validateFields();
            await v4_project_addNewAccount(projectId, values);
        } catch (error) {
            console.error(error);   
        } finally {
            setLoadingAddresses(false);
            setVisible(false);
            if (callback) {
                callback();
            };
        };
    };

    useEffect(() => {
        getAddresses();
    }, [projectId]);

    return (<>
        <div>
            {showAsCard ? (
                <Card>
                    <Button type="link" onClick={() => {
                        setVisible(true);
                    }}>
                        Neuen Account hinzufügen
                    </Button>
                </Card>
            ) : (
                <Button type="link" onClick={() => {
                    setVisible(true);
                }}>
                    Neuen Account hinzufügen
                </Button>
            )}
        </div>

        <Modal
            title="Neuer Account/Kontakt"
            open={visible}
            onCancel={onCancel}
            width={"60%"}
            closable={false}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Abbrechen 
                </Button>,
                <Button key="submit" type="primary" onClick={onSave}>
                    Speichern
                </Button>,
            ]}
            loading={loadingAddresses || loading}
            destroyOnClose
        >
            <div>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={async (values) => {
                        console.log(values);
                        setVisible(false);
                        if (callback) {
                            callback();
                        };
                    }}
                >
                    <FormItem
                        label="Anzeigename"
                        name="name"
                        rules={[{ required: false, message: "Bitte geben Sie einen Namen an." }]}
                    >
                        <Input placeholder="z.B. John Doe oder Einkaufsleitung" />
                    </FormItem>
                    <FormItem
                        label="Vorname"
                        name="firstName"
                        rules={[{ required: true, message: "Bitte geben Sie einen Vornamen an." }]}
                    >
                        <Input placeholder="Vorname" />
                    </FormItem>

                    <FormItem
                        label="Nachname"
                        name="lastName"
                        rules={[{ required: true, message: "Bitte geben Sie einen Nachnamen an." }]}
                    >
                        <Input placeholder="Nachname" />
                    </FormItem>

                    <FormItem
                        label="E-Mail"
                        name="email"
                        rules={[{ required: true, message: "Bitte geben Sie eine E-Mail-Adresse an." }]}
                    >
                        <Input placeholder="E-Mail" />
                    </FormItem>

                    <FormItem
                        label="Telefonnummer"
                        name="phone"
                        rules={[{ required: true, message: "Bitte geben Sie eine Telefonnummer an." }]}
                    >
                        <Input placeholder="Telefonnummer" />
                    </FormItem>

                    <FormItem
                        label="Mobiltelefon"
                        name="mobile"
                        rules={[{ required: false, message: "Bitte geben Sie eine Mobilnummer an." }]}
                    >
                        <Input placeholder="Mobile" />
                    </FormItem>

                    <FormItem
                        label="Kontakt/Account Typ"
                        name="accountType"
                        rules={[{ required: true, message: "Bitte geben Sie einen Account Typ an." }]}
                    >
                        <Select
                            placeholder="Account Typ"
                            options={accountTypeOptions}
                        />
                    </FormItem>

                    <FormItem
                        label="Adresse/Standort"
                        name="address"
                        rules={[{ required: false }]}
                    >
                        <Select
                            placeholder="Adresse/Standort"
                            options={addresses?.map((address) => ({
                                value: address._id,
                                label: `${address?.addressType} | ${address?.street}, ${address?.zip} ${address?.city}`
                            })) || []}
                            notFoundContent={loading ? "Lade Adressen..." : "Keine Adressen gefunden"}
                        />
                    </FormItem>

                    <FormItem
                        label="Notizen"
                        name="notes"
                        rules={[{ required: false, message: "Bitte geben Sie Notizen an." }]}
                    >
                        <Input.TextArea placeholder="Notizen" />
                    </FormItem>

                    <Divider
                        orientation="left"
                        style={{ color: "#333" }}
                    >
                        <Typography.Text>Standardmäßige Einstellungen</Typography.Text>
                    </Divider>

                    <FormItem
                        label={<Typography.Text>Standardmäßiger Versand des Abnahmeblegs</Typography.Text>}
                        name="receivesInvoices"
                        valuePropName="checked"
                    >
                         <Checkbox>
                         Dieser Kontakt erhält standardmäßig Abnahmebelege per Mail
                         </Checkbox>
                    </FormItem>

                    <FormItem
                        label={<Typography.Text>Standardmäßiger Versand des Lieferscheins</Typography.Text>}
                        name="receivesDeliveryNotes"
                        valuePropName="checked"
                    >
                         <Checkbox>
                         Dieser Kontakt erhält standardmäßig Lieferscheine per Mail
                         </Checkbox>
                    </FormItem>

                    <FormItem
                        label={<Typography.Text>Standardmäßiger Versand der Auftragsbestätigung</Typography.Text>}
                        name="receivesOrderConfirmations"
                        valuePropName="checked"
                    >
                        <Checkbox>
                        Dieser Kontakt erhält standardmäßig Auftragsbestätigungen per Mail
                        </Checkbox>
                    </FormItem>
                </Form>
            </div>
        </Modal>

    </>)
}
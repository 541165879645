import { setAPI } from "./api";
import { axiosInstance as axios } from "../axios/axiosInstance";

//API
const API_URL = setAPI();


export async function sendProjectCreation(newProject) {
  return await axios.post(`${API_URL}/api/db/projects/create`, newProject);
};

export async function sendRegistrationRequest(email) {
  return await axios.post(`${API_URL}/register-login/register-req`, { email: email });
};

export async function saveProjectTime(Data, projectId, uid) {
  const timeData = {
    ...Data,
    uid: uid
  };

  console.log("Axios Data: ", timeData);

  return await axios.post(`${API_URL}/api/db/projects/time/track/${projectId}`, timeData)
};

export async function getProjects(userId) {
  try {
    return await axios.post(`${API_URL}/api/db/projects`, { userId: userId})
  } catch (error) {
    console.log(error)
  }
};

export async function getCollections(userId) {
  try {
    return await axios.post(`${API_URL}/api/db/collections`, { userId: userId })
  } catch (error) {
    console.log(error)
  }
};

//Future routers
export async function createTimeCollection(projectData) {
  return await axios.post(`${API_URL}/api/db/projects/time/newcollection`, projectData)
};

export async function filterAndFetchUser(filter) {
  return axios.post(`${API_URL}/api/db/users`, filter)
};


import { Button, Card, Drawer, Form, Modal } from "antd";
import React, { useEffect } from "react";
import { NewOrderForm } from "../../forms/order/NewOrder.form";
import { v4_createNewOrder } from "../../../routing/v4_Routes/order.routes";



export function NewOrderModal({
    showAsCard,
    buttonDisabled,
    callback
}) {
    const [open, setOpen] = React.useState(false);

    const [form] = Form.useForm();

    const onSubmit = async () => {

            try {
                const formValues = await form.validateFields()
                await v4_createNewOrder(formValues);
            } catch (error) {
                console.error(error);
            } finally {
                form.resetFields();
                setOpen(false);
            };
    };

    const onCancel = () => {
        form.resetFields();
        setOpen(false);
    };

    useEffect(() => {

        return () => { //Handled, when the component is unmounted (closed)
            if (callback && typeof callback === "function") {
                if (open) {
                    callback();
                };
            };
        };
    }, [open]);

    return (
        <div>
            <div>
                {showAsCard ? (<>
                    <Card
                        style={{ minWidth: 275, alignContent: "center", textAlign: "center" }}
                    >
                        <Button
                            onClick={() => setOpen(true)}
                            type="link"
                            disabled={buttonDisabled}
                        >
                            Neuen Auftrag erfassen
                        </Button>
                    </Card>
                </>) : (<>
                    <Button
                        onClick={() => setOpen(true)}
                        type="primary"
                        disabled={buttonDisabled}
                    >
                        Neuen Auftrag erfassen
                    </Button>
                </>)}
            </div>

            <Modal
                title="Neuer Auftrag"
                open={open}
                onCancel={onCancel}
                destroyOnClose
                width={"100%"}
                centered
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        onClick={onSubmit}
                    >
                        Speichern
                    </Button>,
                    <Button
                        key="cancel"
                        onClick={() => setOpen(false)}
                    >
                        Abbrechen
                    </Button>
                ]}
            >
                <NewOrderForm
                    form={form}
                />
            </Modal>
        </div>
    );
};
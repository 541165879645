import React from 'react';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig, useAuth } from '../firebase';
import { LayoutSiderHeader } from '../components/global/layout/LayoutSiderHeader';
import NewLoginPage from '../components/pages/NewLoginPage';

const NewLayout = ({ children }) => {

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  //Set back to false before deployment
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return (
      <LayoutSiderHeader>
        {children}
      </LayoutSiderHeader>
    );
  } else {
    return <NewLoginPage />;
  }
};

export default NewLayout;

import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

export function addListenerTo(type, callback) {
    const pathString = `systemListener/${type}`
    const document = doc(db, pathString);
    const unsub = onSnapshot(document, callback, (error) => {
        console.log(error);
    })
    // console.log('Listener added to: ' + pathString);
    return unsub;
};

export function addListenerToPath(path, callback) {
    console.log('Listener added to: ' + path);
    const document = doc(db, path);
    const unsub = onSnapshot(document, callback, (error) => {
        console.log(error);
    })
    // console.log('Listener added to: ' + pathString);
    return unsub;
};

export function addGlobalUserListener(callback) {
    const pathString = `mandants/users`
    const document = doc(db, pathString);
    const unsub = onSnapshot(document, callback, (error) => {
        console.log(error);
    });
    console.log('Added global listener to main document');
    return unsub;
};

export function unsubscribeFrom(listener) {
    console.log('Listener unsubscribed', listener);
  return listener();
};
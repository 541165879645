import { Divider, Table, Typography, Form, Input, DatePicker, Button, Space, Modal, message, Select, TimePicker } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { v4_get_downtime_data, v4_create_downtime, v4_update_downtime, v4_delete_downtime } from "../../../routing/v4_Routes/downtime.routes";
import { v4_get_projects } from "../../../routing/v4_Routes/project.routes";

const { RangePicker } = DatePicker;

export default function DowntimesPage() {
    const [downtimeData, setDowntimeData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
    const [searchParams, setSearchParams] = useState({ project: "", start: null, end: null, search: "" });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingDowntime, setEditingDowntime] = useState(null);
    const [projects, setProjects] = useState([]);
    const [form] = Form.useForm();

    const fetchDowntimeData = async () => {
        setLoading(true);
        const data = await v4_get_downtime_data({
            ...searchParams,
            page: pagination.current,
            limit: pagination.pageSize
        });
        if (data) {
            setDowntimeData(data.items);
            setPagination(prev => ({ ...prev, total: data.total }));
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchDowntimeData();
    }, [pagination.current, pagination.pageSize, searchParams]);

    const handleSearch = (values) => {
        const { dateRange, ...rest } = values;
        setSearchParams({
            ...rest,
            start: dateRange?.[0]?.format('YYYY-MM-DD'),
            end: dateRange?.[1]?.format('YYYY-MM-DD')
        });
        setPagination(prev => ({ ...prev, current: 1 }));
    };

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    const handleAddEdit = async (values) => {
        setLoading(true);
        let success;
        if (editingDowntime) {
            success = await v4_update_downtime(editingDowntime.id, values);
        } else {
            success = await v4_create_downtime(values);
        }
        if (success) {
            message.success(`Downtime erfolgreich ${editingDowntime ? 'aktualisiert' : 'erstellt'}`);
            setIsModalVisible(false);
            form.resetFields();
            fetchDowntimeData();
        }
        setLoading(false);
    };

    const handleDelete = async (id) => {
        const success = await v4_delete_downtime(id);
        if (success) {
            message.success('Downtime erfolgreich gelöscht');
            fetchDowntimeData();
        }
    };

    const fetchProjects = useCallback(async () => {
        const response = await v4_get_projects();
        if (response) {
            setProjects(response);
        };
    }, []);

    useEffect(() => {
        fetchProjects();

        return () => {
            setProjects([]);
        };
    }, [fetchProjects]);

    const columns = [
        { title: 'Projekt', dataIndex: 'project', key: 'project' },
        { title: 'Start', dataIndex: 'start', key: 'start' },
        { title: 'Ende', dataIndex: 'end', key: 'end' },
        {
            title: 'Aktionen',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Button onClick={() => {
                        setEditingDowntime(record);
                        form.setFieldsValue(record);
                        setIsModalVisible(true);
                    }}>Bearbeiten</Button>
                    <Button danger onClick={() => handleDelete(record.id)}>Löschen</Button>
                </Space>
            )
        }
    ];

    return (
        <div>
            <div>
                <Typography.Title level={2}>Downtimes</Typography.Title>
            </div>

            <div>
                <Divider orientation="left">Filter und Suchparameter</Divider>
                <Form layout="inline" onFinish={handleSearch}>
                    <Form.Item name="project" label="Projekt">
                        <Select
                            showSearch
                            placeholder="Projekt auswählen"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear
                            style={{ minWidth: 200, textAlign: 'left' }}
                        >
                            {projects.map(project => (
                                <Select.Option key={project?._id} value={project?._id}>{project?.title}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="dateRange" label="Zeitraum">
                        <RangePicker />
                    </Form.Item>
                    <Form.Item name="search" label="Suche">
                        <Input placeholder="Suchbegriff" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Suchen</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => {
                            setEditingDowntime(null);
                            form.resetFields();
                            setIsModalVisible(true);
                        }}>Neuer Eintrag</Button>
                    </Form.Item>
                </Form>
            </div>

            <div>
                <Divider orientation="left">Downtime-Einträge</Divider>
                <Table
                    columns={columns}
                    dataSource={downtimeData}
                    pagination={pagination}
                    onChange={handleTableChange}
                    loading={loading}
                    rowKey="id"
                />
            </div>

            <Modal
                title={editingDowntime ? "Downtime bearbeiten" : "Neue Downtime erstellen"}
                open={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                    form.resetFields();
                    setEditingDowntime(null);
                }}
                footer={null}
            >
                <Form
                    form={form}
                    onFinish={handleAddEdit}
                    layout="vertical"
                >
                    <Form.Item
                        name="project"
                        label="Projekt"
                        rules={[{ required: true, message: 'Bitte Projekt eingeben' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Projekt auswählen"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ minWidth: 200, textAlign: 'left' }}
                        >
                            {projects.map(project => (
                                <Select.Option key={project?._id} value={project?._id}>{project?.title}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Grund"
                        rules={[{ required: true, message: 'Bitte Grund eingeben' }]}
                    >
                        <Input placeholder="Grund eingeben" />
                    </Form.Item>
                    <Form.Item
                        name="schicht"
                        label="Schicht"
                    >
                        <Select
                            showSearch
                            placeholder="Schicht auswählen"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ minWidth: 200, textAlign: 'left' }}
                        >
                            <Select.Option value="Früh">Früh</Select.Option>
                            <Select.Option value="Spät">Spät</Select.Option>
                            <Select.Option value="Nacht">Nacht</Select.Option>
                            <Select.Option value="Standard">Standard</Select.Option>
                            <Select.Option value="Sonstige">Sonstige</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="date"
                        label="Datum"
                        rules={[{ required: true, message: 'Bitte Datum wählen' }]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        name="startTime"
                        label="Start"
                        rules={[{ required: true, message: 'Bitte Startzeit wählen' }]}
                    >
                        {/* Nur Zeit, format: hh:mm */}
                        <TimePicker format="HH:mm" />
                    </Form.Item>
                    <Form.Item
                        name="endTime"
                        label="Ende"
                        rules={[{ required: true, message: 'Bitte Endzeit wählen' }]}
                    >
                        {/* Nur Zeit, format: hh:mm */}
                        <TimePicker format="HH:mm" onChange={(d) => console.log(d)} />
                    </Form.Item>
                    <Form.Item
                        name="affectedEmployeeCount"
                        label="Anzahl betroffener Mitarbeiter"
                        rules={[{ required: true, message: 'Bitte Dauer eingeben' }]}
                    >
                        <Input type="number" placeholder="Anzahl eingeben" />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                {editingDowntime ? 'Aktualisieren' : 'Erstellen'}
                            </Button>
                            <Button onClick={() => {
                                setIsModalVisible(false);
                                form.resetFields();
                                setEditingDowntime(null);
                            }}>
                                Abbrechen
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
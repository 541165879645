import { Button, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Select, Space, Table } from "antd";
import React, { useState } from "react";
import { useEnvironment } from "../../../hooks/useEnvironment";
import { incotermsCollection } from "../../../data/incotermsCollection";
import datePickerLocale from "../../../locale/datePickerLocale.json";
import TaskSelectorModal from "../../modals/dataEntryModals/TaskSelector.modal";
import _ from "lodash";

export function NewOrderForm({ form }) {

    const { projects, getProject, addresses, invoiceAddresses } = useEnvironment();
    const [modalOpenIndex, setModalOpenIndex] = useState(null);
    
    const handleOpenTaskModal = (index) => {
        const projectId = form.getFieldValue('project');
        if (projectId) {
            setModalOpenIndex(index);
        }
    };

    const setFieldValue = (index, field, value) => {
        // Set at "positions" array
        form.setFieldsValue({
            positions: {
                [index]: {
                    [field]: value
                }
            }
        });
        console.log("Set Field Value: ", field, value);
    };

    // const setDefaultInvoiceAddress = React.useCallback(() => {
    //     const invoiceAddress = _.first(invoiceAddresses?.find(address => address.addressType === "Standard-Rechnungsadresse"))
    //     if (invoiceAddress) {
    //         form.setFieldsValue({
    //             invoiceAddress: invoiceAddress._id
    //         });
    //     };
    // }, [invoiceAddresses, form]);

    // React.useEffect(() => {
    //     if (addresses && invoiceAddresses) {
    //         setDefaultInvoiceAddress();
    //     };
    // }, [addresses, invoiceAddresses, setDefaultInvoiceAddress]);

    return (
        <Form
            form={form}
            layout="vertical"
            name="newOrderForm"
            initialValues={{ remember: true }}
        >

            <Form.Item
                label="Auftragsnummer"
                name="orderNumber"
                tooltip="Auftragsnummer oder  Bestellnummer des Kunden"
                rules={[{ required: true, message: 'Please input the name of the order!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Projekt"
                name="project"
                tooltip="Projekt, zu dem der Auftrag gehört"
                rules={[{ required: true, message: 'Please input the project!' }]}
            >
                <Select
                    placeholder="Projekt auswählen"
                    allowClear
                    onChange={(val) => {
                        if (val) {
                            getProject(val);
                        };
                    }}
                    options={projects.map((project, i) => ({ label: project?.title ?? "Err", value: project?._id ?? `ERRINDX-${i}` }))}
                />
            </Form.Item>

            <Form.Item
                label="Lieferadresse"
                name="deliveryAddress"
                tooltip="Bitte wählen Sie die Lieferadresse aus"
                rules={[{ required: true, message: 'Bitte die Lieferadresse angeben!' }]}
            >
                <Select
                    placeholder="Lieferadresse auswählen"
                    allowClear
                    options={addresses?.map((address, i) => ({ label: `${address?.addressType} | ${address?.name}, ${address?.street}, ${address?.addressLine2 ?? ""}, ${address?.zip} ${address?.city}` ?? "Err", value: address?._id ?? `ERRINDX-${i}` }))}
                />
            </Form.Item>

            <Form.Item
                label="Rechnungsadresse"
                name="invoiceAddress"
                tooltip="Bitte wählen Sie die Lieferadresse aus"
                rules={[{ required: true, message: 'Bitte die Lieferadresse angeben!' }]}
            >
                <Select
                    placeholder="Rechnungsadresse auswählen"
                    allowClear
                    options={invoiceAddresses?.map((address, i) => ({ label: `${address?.addressType} | ${address?.name}, ${address?.street}, ${address?.addressLine2 ?? ""}, ${address?.zip} ${address?.city}` ?? "Err", value: address?._id ?? `ERRINDX-${i}` }))}
                />
            </Form.Item>

            <Form.Item
                label="Beschreibung"
                name="description"
                tooltip="Beschreibung des Auftrags"
                rules={[{ required: false }]}
            >
                <Input.TextArea placeholder="z.B. " />
            </Form.Item>

            <Space>
                <Form.Item
                    label="Incoterms"
                    name="incoterms"
                    tooltip="Incoterms, die für den Auftrag gelten"
                    rules={[{ required: true, message: 'Bitte gebe die Incoterms ein!' }]}
                >
                    <Select
                        placeholder="Incoterm wählen"
                        allowClear
                        options={incotermsCollection.map((incoterm, i) => ({ label: `${incoterm.text} - ${incoterm.description}`, value: incoterm.key }))}
                    />
                </Form.Item>
                <Form.Item
                    label="Liefertermin"
                    name="requestedDeliveryDate"
                    tooltip="Angeforderter Liefertermin"
                    rules={[{ required: true, message: 'Bitte gebe den Liefertermin ein!' }]}
                >
                    <DatePicker
                        locale={datePickerLocale}
                        format={"DD.MM.YYYY"}
                        showHour
                        showMinute
                    />
                </Form.Item>
                <Form.Item
                    label="Bestelldatum"
                    name="orderDate"
                    tooltip="Das Datum andem die Bestellung aufgegeben wurde"
                    rules={[{ required: true, message: 'Bitte gebe das Bestelldatum ein!' }]}
                >
                    <DatePicker
                        locale={datePickerLocale}
                        format={"DD.MM.YYYY"}
                        showHour
                        showMinute
                    />
                </Form.Item>
            </Space>

            <Form.Item>
                <Divider
                    orientation="left"
                >
                    Positionen
                </Divider>
            </Form.Item>

            <div style={{
                display: 'flex',
                rowGap: 16,
                flexDirection: 'column',
                // border: '1px solid #d9d9d9',
                // borderRadius: 15,
                padding: 16
            }}>
                <Form.List name="positions"
                    wrapperCol={{ span: 24 }}
                >
                    {(fields, { add, remove }) => {
                        const columns = [
                            {
                                title: "Position",
                                dataIndex: "posNumber",
                                render: (_, __, index) => (
                                    <Form.Item name={[index, "posNumber"]} style={{ margin: 0 }}>
                                        <InputNumber type="number" />
                                    </Form.Item>
                                ),
                            },
                            {
                                title: "Materialnummer",
                                dataIndex: "matNumber",
                                render: (_, __, index) => (
                                    <Form.Item name={[index, "matNumber"]} style={{ margin: 0 }}>
                                        <Input placeholder="z.B. 0-907-27-782-1" />
                                    </Form.Item>
                                ),
                            },
                            {
                                title: "Menge",
                                dataIndex: "amount",
                                render: (_, __, index) => (
                                    <Form.Item name={[index, "amount"]} style={{ margin: 0 }}>
                                        <InputNumber decimalSeparator="," type="decimal" />
                                    </Form.Item>
                                ),
                            },
                            {
                                title: "Einheit",
                                dataIndex: "unit",
                                render: (_, __, index) => (
                                    <Form.Item name={[index, "unit"]} style={{ margin: 0 }}>
                                        <Select
                                            placeholder="Einheit auswählen"
                                            allowClear
                                            options={[
                                                { label: "Stück", value: "Stück" },
                                                { label: "Kilogramm", value: "Kilogramm" },
                                                { label: "Meter", value: "Meter" },
                                                { label: "Liter", value: "Liter" },
                                                { label: "Kubikmeter", value: "Kubikmeter" },
                                                { label: "Palette", value: "Palette" },
                                            ]}
                                        />
                                    </Form.Item>
                                ),
                            },
                            {
                                title: "Artikel",
                                dataIndex: "article",
                                render: (_, __, index) => (
                                    <Form.Item name={[index, "article"]} style={{ margin: 0 }} shouldUpdate>
                                        <Input
                                            readOnly
                                            placeholder="Ausgewählter Artikel"
                                            suffix={
                                                <Button onClick={() => handleOpenTaskModal(index)}>
                                                    Artikel wählen
                                                </Button>
                                            }
                                        />
                                        {/* Modal anzeigen, falls offen */}
                                        {modalOpenIndex === index && (
                                            <TaskSelectorModal
                                                selectedProjectId={form.getFieldValue('project')}
                                                open={true}
                                                onCancel={() => setModalOpenIndex(null)}
                                                onSelectTask={(val) => {
                                                    console.log("Modal: Selected Task: ", val);
                                                    console.log("Modal: Selected Task Value: ", val?.value);
                                                    setFieldValue(index, "article", val?.value);
                                                    setModalOpenIndex(null);
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                ),
                            },
                            {
                                title: "Preis pro Einheit",
                                dataIndex: "pricePerUnit",
                                render: (_, __, index) => (
                                    <Form.Item name={[index, "pricePerUnit"]} style={{ margin: 0 }}>
                                        <InputNumber decimalSeparator="," type="decimal" />
                                    </Form.Item>
                                ),
                            },
                            {
                                title: "Unterlieferung Zulässig",
                                dataIndex: "unterlieferungZulaessig",
                                render: (_, __, index) => (
                                    <Form.Item
                                        name={[index, "unterlieferungZulaessig"]}
                                        valuePropName="checked"
                                        style={{ margin: 0 }}
                                        initialValue={false}
                                    >
                                        <Checkbox>
                                        Unterliefern OK
                                        </Checkbox>
                                    </Form.Item>
                                ),
                            },
                            {
                                title: "Überlieferung Zulässig",
                                dataIndex: "ueberlieferungZulaessig",
                                render: (_, __, index) => (
                                    <Form.Item
                                        name={[index, "ueberlieferungZulaessig"]}
                                        valuePropName="checked"
                                        style={{ margin: 0 }}
                                        initialValue={false}
                                    >
                                        <Checkbox>
                                        Überliefern OK
                                        </Checkbox>
                                    </Form.Item>
                                ),
                            },
                            {
                                title: "Aktion",
                                dataIndex: "operation",
                                render: (_, __, index) => (
                                    <Button danger onClick={() => remove(index)}>
                                        Entfernen
                                    </Button>
                                ),
                            },
                        ];

                        return (
                            <>
                                <Table
                                    dataSource={fields}
                                    columns={columns}
                                    rowKey="key"
                                    pagination={false}
                                />
                                <Button type="dashed" block onClick={() => add()}>
                                    + Neue Position hinzufügen
                                </Button>
                            </>
                        );
                    }}
                </Form.List>
            </div>

        </Form>
    );
};
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { FileTextTwoTone, ExpandOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { ExpandMore, OpenInBrowserRounded } from '@mui/icons-material';

const PdfPreviewModal = ({ pdfUrl, showAsOpenIcon = false, fileName, disabled = false, customButtonProps }) => {
    const [visible, setVisible] = useState(false);
    const [validUrl, setValidUrl] = useState(false);

    const onClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (pdfUrl) {
            setValidUrl(true);
        }
    }, [pdfUrl]);

    return (
        <>

        {showAsOpenIcon ? (
            <div>
                <Button
                    type="icon"
                    onClick={() => {
                        setVisible(true);
                    }}
                    disabled={!validUrl || disabled}
                    icon={<FileTextTwoTone />}
                >
                    Ansehen
                </Button>
            </div>
        ) : (
            <div>
                <Button
                    type="link"
                    onClick={() => {
                        if (disabled) { return; } else {window.open(pdfUrl, "_blank")}
                    }}  // Opens the pdf in a new tab
                    disabled={!validUrl || disabled}
                    icon={<OpenInBrowserRounded />}
                    {...customButtonProps}
                >
                    {_.truncate(fileName, { length: 25})} öffnen
                </Button>
            </div>
        )}
            <Modal
            open={visible}
            onCancel={onClose}
            footer={null}
            width="80%"
            style={{ top: 20 }}
            destroyOnClose
        >
            <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js`}>
                <div style={{ height: '750px' }}>
                    <Viewer fileUrl={pdfUrl} />
                </div>
            </Worker>
        </Modal>
        </>
    );
};

export default PdfPreviewModal;
import React, { useEffect } from "react";
import { v4_getAllOrders, v4_getOrderById, v4_requestApproval, v4_requestOrderConfirmation } from "../routing/v4_Routes/order.routes";



export const useOrders = () => {
    const [orders, setOrders] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [order, setOrder] = React.useState(null);

    const updateOrders = async () => {
        setLoading(true);
        try {
            const result = await v4_getAllOrders();
            setOrders(result);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        };
    };

    const getOrder = async (orderId) => {
        setLoading(true);
        try {
            const result = await v4_getOrderById(orderId);
            setOrder(result);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        };
    };

    const requestApproval = async (orderId) => {
        setLoading(true);
        try {
            const result = await v4_requestApproval(orderId);
            setOrder(result);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        };
    };

    const createOrderConfirmation = async (orderId) => {
        setLoading(true);
        try {
            const result = await v4_requestOrderConfirmation(orderId);
            console.log("useOrders -> result", result);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        };
    };

    useEffect(() => {
        updateOrders();

        return () => {
            setOrders([]);
            setOrder(null);
        };
    }, []);

    return { 
        orders,
        order,
        loading, 
        refresh: updateOrders,
        getOrder,
        requestApproval,
        createOrderConfirmation,
    };
};
/** Invokes the session data from the backend.
* @param {string} sessionId The session id.
* @returns {object} The session data.
* @example
* const sessionData = invokeSession(sessionId);
* console.log(sessionData);
* // => { ... }
* @example
* import { invokeSession } from "./utils/config/session";
* const sessionData = invokeSession(sessionId);
* console.log(sessionData);
* // => { ... }
* @version 1.0.0
*/

import { auth } from "../../firebase";
import { fetchCurrentUserV1 } from "../../modules/fetchUsers";
import { updateProjects, updateSelectedproject } from "../../redux/projectSlicer";
import store from "../../redux/store";
import { updateFetchingData } from "../../redux/streamSlicer";
import { updateFirebaseUser } from "../../redux/userSlices";
import { getAllProjects } from "../../routing/crudActions";


export async function invokeSession() {
    const user = auth.currentUser;

    try {
        if (user == null) {
            throw new Error("Es ist niemand angemeldet.")
        };

        store.dispatch(updateFirebaseUser(await fetchCurrentUserV1(user)));

        await getAllProjects(store.getState().userState.firebaseUser.mongoRef)
        .then(result => {
            store.dispatch(updateProjects(result.data));
        })
        .catch(error => {
          console.log("Error while retriving information from API. ", error);
        })
        .finally(() => {
            store.dispatch(updateFetchingData(false));
        });
        
    } catch (error) {
        console.log(error);
    };
};

export async function invokeSessionWithProject(desiredProject) {
    const user = auth.currentUser;

    try {
        if (user == null) {
            throw new Error("Es ist niemand angemeldet.")
        };

        store.dispatch(updateFirebaseUser(await fetchCurrentUserV1(user)));
        await getAllProjects(store.getState().userState.firebaseUser.mongoRef)
        .then(result => {
            store.dispatch(updateProjects(result.data));
        })
        .catch(error => {
          console.log("Error while retriving information from API. ", error);
        })
        .finally(() => {
            store.dispatch(updateFetchingData(false));
        });

        const currentProject = store.getState().projectState.projects.find((project) => project._id === desiredProject._id);
        store.dispatch(updateSelectedproject(currentProject));
        
    } catch (error) {
        console.log(error);
        return;
    };
};

export async function refetchUpdatedProjectsArray() {
    try {
        await getAllProjects(store.getState().userState.firebaseUser.mongoRef)
        .then(result => {
            store.dispatch(updateProjects(result.data));
        })
        .catch(error => {
          console.log("Error while retriving information from API. ", error);
        })
        .finally(() => {
            store.dispatch(updateFetchingData(false));
        });
    } catch (error) {
        console.log(error);
        return;
    }
};
import { Button, Modal, Popconfirm, Select, Space, Table, Tag, message } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState, useCallback } from "react";
import { getAllUsers } from "../../../routing/crudActions";
import { v4_deleteUserClaims } from "../../../routing/v4_Routes/admin.routes";
import { RecyclingOutlined, ManageAccountsOutlined, DetailsOutlined, AdminPanelSettingsOutlined } from "@mui/icons-material";
import _ from "lodash";

const USER_ROLES = {
  administrator: { label: "Admin", description: "Erweiterte Berechtigungen", color: "red" },
  employee: { label: "Standard User", description: "Projekt-MA" },
  management: { label: "Manager", description: "" },
  accounting: { label: "Buchhaltung/Finanzen", description: "" },
  projectLead: { label: "Projektleiter", description: "" },
  consultant: { label: "Projektbetreuer", description: "" },
  hr: { label: "Human Resources", description: "" },
  viewer: { label: "Ansicht", description: "" },
  "project-assistance": { label: "Projektassistenz", description: "" }
};

export function AdminUsersModal() {
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [userSearchValue, setUserSearchValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleFetchAllUsers = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await getAllUsers();
            const fetchedUsers = response?.data;
            setUsers(fetchedUsers);
            setFilteredUsers(fetchedUsers);
        } catch (error) {
            console.error("Fehler beim Laden der Benutzer:", error);
            message.error("Benutzer konnten nicht geladen werden");
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleRoleChange = async (value, record) => {
        setIsLoading(true);
        try {
            // await v4_updateUserRole(record.userName, value);

            message.success("Rolle erfolgreich aktualisiert");
            await handleFetchAllUsers();
        } catch (error) {
            console.error("Fehler beim Aktualisieren der Rolle:", error);
            message.error("Rolle konnte nicht aktualisiert werden");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteClaims = async (userName) => {
        setIsLoading(true);
        try {
            await v4_deleteUserClaims(userName);
            message.success("Claims erfolgreich gelöscht");
            await handleFetchAllUsers();
        } catch (error) {
            console.error("Fehler beim Löschen der Claims:", error);
            message.error("Claims konnten nicht gelöscht werden");
        } finally {
            setIsLoading(false);
        }
    };

    const onSearch = useCallback(() => {
        if (!userSearchValue.trim()) {
            setFilteredUsers(users);
            return;
        }

        const searchValueLower = _.lowerCase(userSearchValue);
        setFilteredUsers(
            users?.filter((user) => 
                Object.entries(user)
                    .filter(([key]) => ["firstName", "lastName", "_id", "email", "userName", "role", "timeBoardId", "sharePointId"].includes(key))
                    .some(([, value]) => _.lowerCase(String(value))?.includes(searchValueLower))
            )
        );
    }, [users, userSearchValue]);

    useEffect(() => {
        onSearch();
    }, [userSearchValue, onSearch]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            render: (_, record) => `${record?.firstName} ${record?.lastName}`,
        },
        {
            title: 'Firebase ID',
            dataIndex: 'userName',
            ellipsis: true,
        },
        {
            title: 'Berechtigungen',
            key: 'role',
            render: (_, record) => (
                <Select
                    value={record?.role || "employee"}
                    style={{ width: 350 }}
                    onChange={(value) => handleRoleChange(value, record)}
                    loading={isLoading}
                >
                    {Object.entries(USER_ROLES).map(([value, { label, description, color }]) => (
                        <Select.Option key={value} value={value}>
                            <Space>
                            {label}
                            {description && <Tag color={color || 'blue'}>{description}</Tag>}
                            </Space>
                        </Select.Option>
                    ))}
                </Select>
            )
        },
        {
            title: 'Aktionen',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Popconfirm
                    title="Claims löschen"
                    description="Möchten Sie wirklich alle Claims dieses Benutzers löschen?"
                    onConfirm={() => handleDeleteClaims(record?.userName)}
                    okText="Ja"
                    cancelText="Nein"
                    okType="danger"
                >
                    <Button 
                        type="link"
                        danger
                        icon={<RecyclingOutlined />}
                        loading={isLoading}
                    >
                        Claims löschen
                    </Button>
                </Popconfirm>
                <Button
                    type="link"
                    icon={<AdminPanelSettingsOutlined />}
                    loading={isLoading}
                    >
                        Details ansehen
                    </Button>
                </Space>
            )
        }
    ];

    return (
        <>
            <Button 
                onClick={() => {
                    setOpen(true);
                    handleFetchAllUsers();
                }}
                icon={<ManageAccountsOutlined />}
            >
                Benutzerverwaltung
            </Button>
            
            <Modal
                title="Benutzerverwaltung (Admin)"
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}
                width={"80%"}
                destroyOnClose
            >
                <Search
                    placeholder="Benutzer suchen..."
                    size="large"
                    value={userSearchValue}
                    onChange={(e) => setUserSearchValue(e.target.value)}
                    allowClear
                    style={{ marginBottom: 16 }}
                />
                
                <Table
                    columns={columns}
                    dataSource={filteredUsers}
                    loading={isLoading}
                    rowKey="userName"
                    pagination={{ pageSize: 10 }}
                />
            </Modal>
        </>
    );
}
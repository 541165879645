
import { axiosInstance } from "../../axios/axiosInstance";
import { displayErrorModal, displaySuccessModal } from "../../utils/messageServices/modalService";
import { v4_setAPI } from "../api";

const API_URL = v4_setAPI();


export async function v4_startTimeboardCalculation(qry) {
    try {
        const response = await axiosInstance.post(`${API_URL}/manage/cost/personal/calculate`, {
            startDate: qry.startDate.toDate(),
            endDate: qry.endDate.toDate(),
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.log(error);
        displayErrorModal('Fehler beim Abrufen der Kosten', error?.response?.data?.error, error?.response?.data?.stack);
    };
};


export async function v4_getBackendLogs(page, limit, level) {
    try {
        let response = null;
        response = await axiosInstance.get(`${API_URL}/manage/backend/logs`, { params: { 
            page: page,
            limit: limit,
            level: level
         } });
         console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};

export async function v4_getCountOfBackendLogs() {
    try {
        const response = await axiosInstance.get(`${API_URL}/manage/backend/logs/count`);
        console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
}

export async function v4_deleteUserClaims(firebaseId) {
    try {
        const result = await axiosInstance.delete(`${API_URL}/users/user/${firebaseId}/claims`);
        console.log(result);
        displaySuccessModal("Benutzeransprüche erfolgreich zurückgesetzt", "Die Ansprüche des Benutzers wurden erfolgreich zurückgesetzt.");
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Zurücksetzen der Benutzeransprüche", error?.response?.data?.error, error?.response?.data?.stack);
    }
}
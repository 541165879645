//eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Button, Descriptions, InputNumber, Popconfirm, Select, Space, Table, Tag } from "antd";
import { Container } from "@mui/system";
import AddUserDialog from "../../../global/dialogs/AddUserDialog";
import { fetchAllUsersWith } from "../../../../routing/userRouting";
import store from "../../../../redux/store";
import _ from "lodash";
import ServiceNoticeDialog from "../../../global/dialogs/ServiceNoticeDialog";
import { AddUserModal } from "../../../modals/AddUserModal";
import { calculateCosts, route_removeDuplicateCosts } from "../../../../routing/userManagement";
import SimpleBackdrop from "../../../../components/global/LoadingBackdrop"
import { ReAuthenticationFormModal } from "../../../security/ReAuthenticationForm.modal";
import { v4_change_user_role } from "../../../../routing/v4_Routes/user.routes";
import Search from "antd/es/input/Search";
import { displayErrorModal, displayInfoModal, displaySuccessModal, displayWarningModal } from "../../../../utils/messageServices/modalService";
import { v4_test_route_error } from "../../../../routing/v4_Routes/test.routes";
import { CalculateV2PersonalCostsModal } from "../../../modals/costs/CalculateV2PersonalCosts.modal.js";
import { v4_deleteUserClaims } from "../../../../routing/v4_Routes/admin.routes.js";
import { RecyclingRounded } from "@mui/icons-material";
import { getAllUsers } from "../../../../routing/crudActions.js";
import { AdminUsersModal } from "../../../modals/admin/AdminUser.modal.jsx";

function AdminPage() { 

    const [userDialog, setUserDialog] = useState(false);

    //eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const [serviceNoticeDialogOpen, setServiceNoticeDialogOpen] = useState(false);
    const [shiftNumber, setShiftNumber] = useState(0);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [currValue, setCurrValue] = useState(null);
    const [currId, setCurrId] = useState(null);
    const [calculateV2Costs, setCalculateV2Costs] = useState(false);

    const handleCalculation = async () => {
        setIsLoading(true);
        await calculateCosts({ shiftNumber: shiftNumber, cronId: `CRON_${Date.now()}_FED_MANUAL_CALC` })
            .then((result) => {
                console.log(result);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const removeDuplicateCosts = async () => {
        setIsLoading(true);
        await route_removeDuplicateCosts()
            .then((result) => {
                console.log(result);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    const displayTestError = (errType = "error") => {

        switch (errType) {
            case "error":
                displayErrorModal("Test Error", "This is a test error message.");
                break;
            case "success":
                displaySuccessModal("Test Success", "This is a test success message.");
                break;
            case "warning":
                displayWarningModal("Test Warning", "This is a test warning message.");
                break;
            case "info":
                displayInfoModal("Test Info", "This is a test info message.");
                break;
            default:
                console.log("No error type specified.");
                break;
        }

    };

    const loadingBackdropTest = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 5000);
    };

    async function testRouteError() {
        setIsLoading(true);
        const response = await v4_test_route_error();
        console.log(response);
        setIsLoading(false);
    };

    function thorwUnhandledRejection() {
        Promise.reject("Unhandled Test-Rejection");
    };
        

    return (
        <>
            <Container sx={{ mt: 5 }}>
                <Descriptions title="Allgemein" bordered>
                    <Descriptions.Item label="Sitzung">Sitzungs-Id</Descriptions.Item>
                    <Descriptions.Item label="Web Version">Version</Descriptions.Item>
                    <Descriptions.Item label="Umgebung">Development</Descriptions.Item>
                    <Descriptions.Item label="Letzte Commit">2018-04-24 18:00:00</Descriptions.Item>
                    <Descriptions.Item label="Commit Nummer" span={2}>
                        Unbekannt
                    </Descriptions.Item>
                </Descriptions>

                <Descriptions title="Einstellungen" bordered style={{ marginTop: 20, textAlign: "left" }} >
                    <Descriptions.Item label="User">
                        <Button type="link" onClick={() => { setUserDialog(true) }}>
                            Nutzer anlegen
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="User">
                        <AdminUsersModal />
                    </Descriptions.Item>
                    <Descriptions.Item label="Web Version" span={2}>{navigator.appVersion}</Descriptions.Item>
                    <Descriptions.Item label="Umgebung">YES</Descriptions.Item>
                </Descriptions>

                <Descriptions title="Testfunktionen" bordered style={{ marginTop: 20, textAlign: "left" }} >
                    <Descriptions.Item label="Error Modal">
                        <Button type="link" onClick={() => { displayTestError("error") }}>
                            Test Fehlermeldung global
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Success Modal">
                        <Button type="link" onClick={() => { displayTestError("success") }}>
                            Test Erfolgsmeldung global
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Warning Modal">
                        <Button type="link" onClick={() => { displayTestError("warning") }}>
                            Test Warnung global
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Info Modal">
                        <Button type="link" onClick={() => { displayTestError("info") }}>
                            Test Info global
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Loading Backdrop">
                        <Button type="link" onClick={loadingBackdropTest}>
                            Test Ladebildschirm
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Test Route Error">
                        <Button type="link" onClick={testRouteError} loading={isLoading}>
                            Test Route Error (Async)
                        </Button>
                    </Descriptions.Item>

                    <Descriptions.Item label="Unhandled Rejection">
                        <Button type="link" onClick={thorwUnhandledRejection}>
                            Unhandled Rejection
                        </Button>
                    </Descriptions.Item>
                </Descriptions>

                <Descriptions title="Aktionen" bordered style={{ marginTop: 20, textAlign: "left" }} >
                    <Descriptions.Item label="Kostenberechnung anstoßen" span={6}>
                        <Space>
                            <Button type="link" onClick={handleCalculation} danger>
                                Kostenberechnung anstoßen
                            </Button>
                            <InputNumber type="number" placeholder="Anzahl der Tagesversätze" onChange={
                                (value) => {
                                    setShiftNumber(value);
                                } 
                            } />
                        </Space>
                    </Descriptions.Item>

                    <Descriptions.Item label="Kosten Duplikate entfernen" span={6}>
                        <Space>
                            <Button type="link" onClick={removeDuplicateCosts} danger>
                                Duplikate entfernen
                            </Button>
                        </Space>
                    </Descriptions.Item>

                    <Descriptions.Item label="Service Offline Notice" span={6}>
                        <Button type="link" onClick={() => { setServiceNoticeDialogOpen(true) }} danger>
                            SON Konfigurieren
                        </Button>
                    </Descriptions.Item>

                    <Descriptions.Item label="Kosten berechnen v2" span={6}>
                        <Button type="link" onClick={() => { setCalculateV2Costs(true) }} danger>
                            v2 Kostenberechnung
                        </Button>
                    </Descriptions.Item>

                    <Descriptions.Item label="Azure" span={6}>
                        <Button type="link" onClick={() => { setUserDialog(true) }}>
                            Manage Azure Network
                        </Button>
                    </Descriptions.Item>

                    <Descriptions.Item label="Firebase" span={6}>
                        <Button type="link" onClick={() => { setUserDialog(true) }}>
                            Manage Firebase
                        </Button>
                    </Descriptions.Item>

                </Descriptions>


            </Container>
            
            <CalculateV2PersonalCostsModal open={calculateV2Costs} onClose={() => { setCalculateV2Costs(false) }} />
            <AddUserModal DetailedUserTypes={true} Admin={true} OpenDialog={userDialog} setOpen={() => { setUserDialog(false) }} />
            <ServiceNoticeDialog OpenDialog={serviceNoticeDialogOpen} setOpen={() => { setServiceNoticeDialogOpen(false) }} />
            {isLoading && <div>
                <SimpleBackdrop open={isLoading} />
            </div>}
            <ReAuthenticationFormModal Open={confirmModalOpen} OnCancel={() => {
                setConfirmModalOpen(false);
                setCurrId(null);
                setCurrValue(null);
            }}
                OnSuccess={async () => {
                    setIsLoading(true);
                    await v4_change_user_role(currId, currValue)
                    setIsLoading(false);
                    setCurrId(null);
                    setCurrValue(null);
                }}
            />
        </>
    );
};


export default AdminPage;
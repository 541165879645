import { auth } from "../../firebase";
import { v4_setAPI } from "../api";
import _  from "lodash";
import { displayErrorModal, displaySuccessModal } from "../../utils/messageServices/modalService";
import { axiosInstance } from "../../axios/axiosInstance";
import { Space, Typography } from "antd";

const API_URL = v4_setAPI();
/**
 * 
 * @param {File} file 
 * @returns 
 */
export async function v4_upload_article_list(file, _id) {
    try {
        let response = null;
        const formData = new FormData();
        formData.append("file", file);
        response = await axiosInstance.post(`${API_URL}/articles/${_id}/import/articles`, formData);

        console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};


export async function v4_price_change(project_id, article_id, formData) {
    try {
        console.log(project_id, article_id, formData);
        if (_.isEmpty(project_id) || _.isEmpty(article_id) || _.isEmpty(formData)) {
            throw new Error("Ungültige parameter");
        };

        let response = await axiosInstance.post(`${API_URL}/articles/${project_id}/${article_id}/update/price`, formData);
        displaySuccessModal("Preisänderung", <div>
            <Space direction="vertical">
            <Typography.Text>
                {response?.data?.message || "Es scheint etwas mit dem Antworttext nicht zu stimmen... (Nicht so schlimm...)"}
            </Typography.Text>
            </Space>
        </div>);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Ein Fehler ist aufgetreten", error?.response?.data?.error || error?.message, error?.response?.data?.stack)
        return null;
    };
};

export async function v4_property_change(project_id, article_id, formData) {
    try {
        console.log(project_id, article_id, formData);
        if (_.isEmpty(project_id) || _.isEmpty(article_id) || _.isEmpty(formData)) {
            throw new Error("Ungültige parameter");
        };

        let response = await axiosInstance.post(`${API_URL}/articles/${project_id}/${article_id}/update/property`, formData);
        displaySuccessModal("Preisänderung", <div>
            <Space direction="vertical">
            <Typography.Text>
                {response?.data?.message || "Es scheint etwas mit dem Antworttext nicht zu stimmen... (Nicht so schlimm...)"}
            </Typography.Text>
            </Space>
        </div>);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Ein Fehler ist aufgetreten", error?.response?.data?.error || error?.message, error?.response?.data?.stack)
        return null;
    };
};

export async function v4_price_change_preview(project_id, article_id, formData) {
    try {
        console.log(project_id, article_id, formData);
        if (_.isEmpty(project_id) || _.isEmpty(article_id) || _.isEmpty(formData)) {
            throw new Error("Ungültige parameter");
        };

        let response = await axiosInstance.post(`${API_URL}/articles/preview/${project_id}/${article_id}/update/price`, formData);
        displaySuccessModal("Preisänderungsvorschau", <div>
            <Space direction="vertical">
            <Typography.Text>
                {response?.data?.message || "Es scheint etwas mit dem Antworttext nicht zu stimmen..."}
            </Typography.Text>
            <Typography.Text>
                {response?.data?.description || "Es scheint etwas mit dem Antworttext nicht zu stimmen..."}
            </Typography.Text>
            </Space>
        </div>);
        return
    } catch (error) {
        console.error(error);
        displayErrorModal("Ein Fehler ist aufgetreten", error?.response?.data?.error || error?.message, error?.response?.data?.stack)
        return null;
    };
}

export async function v4_new_custom_property(project_id, article_id, formData) {
    try {
        console.log(project_id, article_id, formData);
        if (_.isEmpty(project_id) || _.isEmpty(article_id) || _.isEmpty(formData)) {
            throw new Error("Ungültige parameter");
        };

        let response = await axiosInstance.post(`${API_URL}/articles/${project_id}/${article_id}/new/custom-property`, formData);
        
        if (response.data instanceof Error) {
            throw response;
        };
        
        displaySuccessModal("Neue Artikeleigenschaft", <div>
            <Space direction="vertical">
            <Typography.Text>
                {response?.data?.message || "Es scheint etwas mit dem Antworttext nicht zu stimmen... (Nicht so schlimm...)"}
            </Typography.Text>
            </Space>
        </div>);

        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Ein Fehler ist aufgetreten", error?.response?.data?.error || error?.message, error?.response?.data?.stack)
        return null;
    };
};
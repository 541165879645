import { Descriptions, Typography } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { util_ProjectDetailsUpdate, util_ProjectLocationUpdate } from "../../../utils/updaterUtils/projectUpdateUtil";
import _ from "lodash";

const { Paragraph } = Typography;

const BaseProjectDataForm = (props) => {

    const p = useSelector((state) => state.projectState.selectedProject)
    const project = props.project || p;

    const [loading, setLoading] = useState(false);

    const handleUpperPropertyChange = async (value, fieldName) => {
        setLoading(true);
        await util_ProjectDetailsUpdate(project._id, fieldName, value)
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            });
    };

    const handleNestedPropertyChange = async (value, fieldName) => {
        setLoading(true);
        await util_ProjectLocationUpdate(project._id, fieldName, value)
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            });
    };



    return (
        <div>
                    <div style={{
            width: "100%",
            height: "100%",
            // display: "flex",
            justifyContent: "left",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "1rem",
            marginBottom: "50px",
        }}>
            <Descriptions title="Stammdaten" bordered column={2}>
                <Descriptions.Item label="Projektname (nicht änderbar)" span={8}><Paragraph>
                    {project?.title}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Lieferantennummer"><Paragraph editable={{
                        onChange: (value) => { handleUpperPropertyChange(value, "supplierNumber") },
                    }}>
                    {!loading ? project?.supplierNumber : "N/A"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Administrative Notiz"><Paragraph editable={{
                        onChange: (value) => { handleUpperPropertyChange(value, "administrationNote") },
                    }}>
                    {!loading ? project?.administrationNote : "N/A"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Kostenstelle"><Paragraph editable={{
                        onChange: (value) => { handleUpperPropertyChange(value, "costCentre") },
                    }}>
                    {!loading ? project?.costCentre : "Bitte Kostenstelle angeben"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Project Currency"><Paragraph>
                    Standard | EUR (€)
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Firma"><Paragraph>
                    {!loading ? project?.projectLocation?.companyName : "N/A"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Straße, Nr."><Paragraph editable={{
                        onChange: (value) => { handleNestedPropertyChange(value, "adress") },
                    }}>
                    {!loading ? project?.projectLocation?.adress : "N/A"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Stadt"><Paragraph editable={{
                        onChange: (value) => { handleNestedPropertyChange(value, "cityName") },
                    }}>
                    {!loading ? project?.projectLocation?.cityName : "N/A"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="PLZ"><Paragraph editable={{
                        onChange: (value) => { handleNestedPropertyChange(value, "zipCode") },
                    }}>
                    {!loading ? project?.projectLocation?.zipCode : "00000"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Bundesland"><Paragraph editable={{
                        onChange: (value) => { handleNestedPropertyChange(value, "county") },
                    }}>
                    {!loading ? project?.projectLocation?.county : "N/A"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Land"><Paragraph editable={{
                        onChange: (value) => { handleNestedPropertyChange(value, "country") },
                    }}>
                    {!loading ? project?.projectLocation?.country : "N/A"}
                    </Paragraph></Descriptions.Item>
            </Descriptions>
        </div>
        <div style={{
            width: "100%",
            height: "100%",
            // display: "flex",
            justifyContent: "left",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "1rem",
            marginBottom: "50px",
        }}>
            <Descriptions title="Kunden-Ansprechpartner 1 (Hauptansprechpartner)" bordered>
                <Descriptions.Item label="Vollständiger Name" span={8}><Paragraph editable={{
                        onChange: (value) => { handleUpperPropertyChange(value, "customerContact1.fullName") },
                    }}>
                    {_.has(project, "customerContact1.fullName") ? project.customerContact1.fullName : "NV"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Email"><Paragraph editable={{
                        onChange: (value) => { handleUpperPropertyChange(value, "customerContact1.email") },
                    }}>
                    {_.has(project, "customerContact1.email") ? project.customerContact1.email : "NV"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Telefonnummer"><Paragraph editable={{
                        onChange: (value) => { handleUpperPropertyChange(value, "customerContact1.phone") },
                    }}>
                {_.has(project, "customerContact1.phone") ? project.customerContact1.phone : "NV"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Interne Notizen" span={8}><Paragraph editable={{
                        onChange: (value) => { handleUpperPropertyChange(value, "customerContact1.notes") },
                    }}>
                {_.has(project, "customerContact1.notes") ? project.customerContact1.notes : "NV"}
                </Paragraph></Descriptions.Item>
            </Descriptions>
        </div>

        <div style={{
            width: "100%",
            height: "100%",
            // display: "flex",
            justifyContent: "left",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "1rem",
            marginBottom: "50px",
        }}>
            <Descriptions title="Ansprechpartner 2 (Sekundärer Ansprechpartner)" bordered>
                <Descriptions.Item label="Vollständiger Name" span={8}><Paragraph editable={{
                        onChange: (value) => { handleUpperPropertyChange(value, "customerContact2.fullName") },
                    }}>
                    {_.has(project, "customerContact2.fullName") ? project.customerContact2.fullName : "NV"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Email"><Paragraph editable={{
                        onChange: (value) => { handleUpperPropertyChange(value, "customerContact2.email") },
                    }}>
                    {_.has(project, "customerContact2.email") ? project.customerContact2.email : "NV"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Telefonnummer"><Paragraph editable={{
                        onChange: (value) => { handleUpperPropertyChange(value, "customerContact2.phone") },
                    }}>
                {_.has(project, "customerContact2.phone") ? project.customerContact2.phone : "NV"}
                </Paragraph></Descriptions.Item>
                <Descriptions.Item label="Interne Notizen" span={8}><Paragraph editable={{
                        onChange: (value) => { handleUpperPropertyChange(value, "customerContact2.notes") },
                    }}>
                {_.has(project, "customerContact2.notes") ? project.customerContact2.notes : "NV"}
                </Paragraph></Descriptions.Item>
            </Descriptions>
        </div>
        </div>
    );
};

export { BaseProjectDataForm };
import { Button, Card, Checkbox, Form, Input, Modal, Select, Typography, Upload } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { displayInfoModal } from "../../../utils/messageServices/modalService";
import { v4_uploadMultipleFiles, v4_uploadSingleFile } from "../../../routing/v4_Routes/document.routes";

const { Text } = Typography;

const docTypeOptions = [
    { value: "Auftrag", label: "Auftrag/Auftragsunterlagen" },
    { value: "projectDocumentation", label: "Projektdokumentation" },
    { value: "CustomerAccountDocuments", label: "Kundenbezogenes Dokument" },
    { value: "AddressDocuments", label: "Adressbezogenes Dokument" },
    { value: "Document", label: "Allgemeines Dokument" },
    { value: "Other", label: "Sonstige Dokumente" },
];

export function FileUploaderModal({
    callback,
    targetProperty = "documents", //Prop of where the ref should be stored
    modelElementLinkId = null, //ObjectId
    modelName = "Project", //Model name
    _id = null,
    showAsCard = true,
    disabled = false,
    buttonText = "Dokument hochladen",
    multiple = false,
}) {
    
    const [loading, setLoading] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [fileList, setFileList] = React.useState([]);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const userId = useSelector((state) => state.userState.mongoUser?._id);
    const [form] = Form.useForm();

    const handleUpload = async () => {
        try {
            const values = await form.validateFields();
            const { docName, docType, prvt } = values;

            if (_.isEmpty(fileList)) {
                throw new Error("No file selected");
            };

            if (!_id) {
                throw new Error("No project ID provided");
            };

            setLoading(true);

            if (fileList.length === 0) {
                throw new Error("No file selected");
            };

            if (multiple) {
                await v4_uploadMultipleFiles(targetProperty, modelElementLinkId, modelName, docType, _id, fileList);
            } else {
                await v4_uploadSingleFile(targetProperty, modelElementLinkId, modelName, docName, docType, prvt, _id, fileList[0]);
            }

        } catch (error) {
            console.error(error);
            displayInfoModal("Fehler beim Hochladen", "Es ist ein Fehler aufgetreten. Bitte versuche es erneut.");
            setLoading(false);
        } finally {
            setLoading(false);
            if (callback) {
                callback();
            };
            setFileList([]);
            setModalOpen(false);
        };
    };

    const onCancel = () => {
        setFileList([]);
        form.resetFields();
        setModalOpen(false);
    };

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        maxCount: multiple ? 10 : 1,
        multiple,
        accept: ".csv, text/csv, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .dxf",
    };

    return (
        <>
            {showAsCard ? (<>
                <Card>
                    <Button type="link"
                    disabled={disabled}
                    onClick={() => {
                        setModalOpen(true);
                    }}>
                        {buttonText}
                    </Button>
                </Card>
            </>) : (<>
                <Button type="link"
                disabled={disabled}
                onClick={() => {
                    setModalOpen(true);
                }}>
                    {buttonText}
                </Button>
            </>)}
            <Modal
                title="Dokument hochladen"
                open={modalOpen}
                onCancel={onCancel}
                footer={[
                    <Button key="back" onClick={onCancel}>
                        Abbrechen
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleUpload}>
                        Hochladen
                    </Button>,
                ]}
                maskClosable={false}
                destroyOnClose
            >
                <br />
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={async (values) => {
                            console.log(values);
                        }}
                        initialValues={{
                            prvt: true,
                        }}
                    >
                        <Form.Item
                            label="Dokumentname"
                            name="docName"
                            rules={[{ required: !multiple, message: "Bitte gib einen Dokumentnamen ein" }]}
                            hidden={multiple}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Dokumenttyp"
                            name="docType"
                            rules={[{ required: true, message: "Bitte gib einen Dokumenttyp ein" }]}
                        >
                            <Select
                                showSearch
                                placeholder="Dokumenttyp auswählen"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={docTypeOptions}
                                />
                        </Form.Item>
                        <Form.Item
                            label="Privat"
                            name="prvt"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Form>
                <br />
                <br />
                <Upload {...uploadProps}
                    
                >
                    <Button
                        type="primary"
                        loading={loading}
                        icon={loading ? <LoadingOutlined /> : <UploadOutlined />}
                    >Datei Auswählen</Button>
                </Upload>
            </Modal>
        </>
    )
};


import { isEmpty } from "lodash";
import { setAPI } from "./api";
import { axiosInstance as axios } from "../axios/axiosInstance";

const API_URL = setAPI();

export async function addFieldToCustomSchema(id, fields) {
  try {
    if (id === "" || isEmpty(fields)) {
      throw new Error("Preparing sync-stream failed. id or fields should not be empty.")
    }
    return await axios.post(`${API_URL}/api/db/schema/projects/create`, { mongoId: id, fields: fields })
  } catch (error) {
    console.log(error);
  };
};

export async function updateSchema(id, projectIdName, project) {
  try {
    if (id || projectIdName === "") {
      throw new Error("Preparing sync-stream failed. id or projectIdName should not be empty.")
    }
    return await axios.post(`${API_URL}/api/db/schema/schema/sync/${projectIdName}`, { mongoId: id, project: project })
  } catch (error) {
    console.log(error);
  };
};

//Not used yet
export async function getSchema(id) {
  try {
    return await axios.post(`${API_URL}/api/db/mo/stream`, { mongoId: id })
  } catch (error) {
    console.log(error)
  }
}
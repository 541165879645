import React, { useMemo, useRef, useState, useEffect } from "react";
import { Modal, Select, Spin, Button, Table } from "antd";
import debounce from "lodash/debounce";
import { route_searchArticle } from "../../../routing/v3_Routes/v3_Routes";
import Search from "antd/es/input/Search";

function TaskSelectorModal({
    selectedProjectId,
    open,
    onCancel,
    onSelectTask,
}) {
  const [value, setValue] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const loadOptions = async (inputValue) => {
    if (!selectedProjectId) return [];
    
    fetchRef.current += 1;
    const currentFetchId = fetchRef.current;
    setFetching(true);

    try {
      const result = await route_searchArticle(selectedProjectId, false, inputValue);
      if (currentFetchId !== fetchRef.current) return [];

      const formattedOptions = result?.map((article) => ({
        value: article?._id,
        label: `${article.title} (${article.uniqueArticleNumber})`,
        articleData: article
      })) || [];
      
      setOptions(formattedOptions);
    } catch (error) {
      console.error('Fehler beim Laden der Artikel:', error);
    } finally {
      setFetching(false);
    }
  };

  const debounceFetcher = useMemo(() => {
    return debounce(loadOptions, 600);
  }, [selectedProjectId]);

  const handleChange = (newVal) => {
    setValue(newVal); // newVal ist ein Objekt mit value und label
  };

  const handleSelect = (value) => {
    if (!value) return;
    
    const selectedOption = options.find(opt => opt.value === value.value);
    if (selectedOption) {
      onSelectTask({
        value: selectedOption.value,
        label: selectedOption.label,
      });
    }
  };

  useEffect(() => {
    if (!open) {
      setValue(null);
      setOptions([]);
    }
  }, [open]);

  return (
    <Modal
      title="Aufgabe oder Artikel auswählen"
      open={open}
      onCancel={onCancel}
      footer={null}
      width={"80%"}
    >
    <Search
        placeholder="Artikel suchen"
        onChange={(e) => debounceFetcher(e.target.value)}
        allowClear
      />
      <Table
        columns={[
          {
            title: "Artikel",
            dataIndex: "label",
          },
          {
            title: "Artikel",
            dataIndex: "value",
          },
          {
            title: "Beschreibung",
            dataIndex: "articleData",
            render: (article) => article?.description || "Keine Beschreibung",
          },
          {
            title: "Artikelnummer",
            dataIndex: "articleData",
            render: (article) => article?.articleNumber || "Keine Artikelnummer",
          },
          {
            title: "Preis",
            dataIndex: "articleData",
            render: (article) => article?.price || "Kein Preis",
          },
          {
            title: "Aktionen",
            key: "actions",
            render: (record) => (
              <Button 
                type="primary" 
                onClick={() => {
                  handleSelect(record);
                  }}
              >
                Auswählen
              </Button>
            ),
          }
        ]}
        showHeader={false}
        dataSource={options}
        pagination={false}
        rowKey={(record) => record?.articleData?._id}
        onRow={(record) => {
          return {
            onClick: () => {
              setValue(record);
              console.log("record:", record);
            },
          };
        }}
        rowHoverable
        loading={fetching}
      />
      <div style={{ marginTop: "1rem" }}>

      </div>
    </Modal>
  );
}

export default TaskSelectorModal;

import React, { useEffect } from "react";
import { v4_get_projects, v4_getProjectById } from "../routing/v4_Routes/project.routes";



export const useEnvironment = () => {
    const [projects, setProjects] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [project, setProject] = React.useState(null);
    const [addresses, setAddresses] = React.useState([]);
    const [invoiceAddresses, setInvoiceAddresses] = React.useState([]);

    async function updateProjects() {
        setLoading(true);
        try {
            const result = await v4_get_projects();
            setProjects(result);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        };
    };

    async function getProject(projectId) {
        setLoading(true);
        try {
            const result = await v4_getProjectById(projectId);
            setProject(result);
            setAddresses(result?.addresses || []);
            const invoiceAddresses = result?.addresses?.filter(address => address.addressType === "Standard-Rechnungsadresse" || address.addressType === "Rechnungsadresse");
            setInvoiceAddresses(invoiceAddresses);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        };
    };

    useEffect(() => {
        updateProjects();

        return () => {
            setProjects([]);
            setProject(null);
            setAddresses([]);
        };
    }, []);

    return { 
        projects,
        loading,
        updateProjects,
        getProject,
        project,
        addresses,
        invoiceAddresses
    };
};
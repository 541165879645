import { Button, Card, Col, Descriptions, Divider, Modal, Space, Table, Typography } from "antd";
import React, { useEffect } from "react";
import { useOrders } from "../../../hooks/useOrders";
import { parseOrderStatus } from "../../../utils/helpers/parseOrderStatus.util";
import { DownOutlined, DownloadOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { useAuth } from "../../../firebase";
import { useUpdate } from "ahooks";
import _ from "lodash";

const { Text } = Typography;

export function OrderDocumentsModal({ orderId , customButtonProps }) {
    const { order, getOrder, loading } = useOrders();
    const { hasPermission } = useAuth();
    const [documents, setDocuments] = React.useState([]);
    const [modalOpen, setModalOpen] = React.useState(false);
    const update = useUpdate();

    const initModal = async () => {
        setModalOpen(true)
        await getOrder(orderId);

    }

    useEffect(() => {
        if (order && hasPermission("accounting")) {
            console.log("OrderDocumentsModal -> order", order);
            const docs = [order?.orderDocument, ...order?.publicDocuments, ...order?.privateDocuments, ...order?.deliveryNotes];
            setDocuments(_.compact(docs));
            update();
        } else if (order) {
            console.log("OrderDocumentsModal -> order", order);
            setDocuments([...order?.publicDocuments, ...order?.deliveryNotes] || []);
        }

        return () => {
            setDocuments([]);
        };
    }, [order]);

    return (<>

        <div>
            <Button type="link"
                onClick={() => {
                    initModal();
                    }}
                icon={<FolderOpenOutlined />}
                loading={loading}
                {...customButtonProps}
            >Unterlagen</Button>
        </div>
        <Modal
            title={`Auftrag: ${order?.orderNumber}`}
            open={modalOpen}
            onCancel={() => setModalOpen(false)}
            footer={null}
            width="80%"
            destroyOnClose
        >
            <div style={{ marginBottom: "1rem" }}>
                <Typography.Title level={5}>
                    Kundenauftragsdokumente {parseOrderStatus(order?.status)}
                </Typography.Title>
            </div>


            <div style={{ marginBottom: "1rem" }}>
                <Descriptions bordered column={2}>
                    <Descriptions.Item label="hpm Auftrag">
                        <Text copyable>{order?._id}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Kundenauftrag">
                        <Text copyable>{order?.orderNumber}</Text>
                    </Descriptions.Item>
                </Descriptions>
            </div>

            <div>
                <div>
                    <Divider orientation="left" style={{ color: "#333" }}>
                        <Typography.Text>Dokumente (öffentlich)</Typography.Text>
                    </Divider>

                    <div>

                        <div>
                            <Table
                                columns={[
                                    {
                                        title: "Name",
                                        dataIndex: "name",
                                    },
                                    {
                                        title: "Typ",
                                        dataIndex: "docType",
                                    },
                                    {
                                        title: "Hochgeladen",
                                        dataIndex: "createdAt",
                                        render: (text) => new Date(text).toLocaleDateString(),
                                    },
                                    {
                                        title: "Aktionen",
                                        render: (text, record) => (
                                            <Space size="middle">
                                                <Button
                                                    type="link"
                                                    icon={<DownloadOutlined />}
                                                    onClick={() => window.open(record.link, "_blank")}
                                                >
                                                    Öffnen
                                                </Button>
                                            </Space>
                                        ),
                                    }
                                ]}
                                dataSource={documents}
                                rowKey={record => record?._id}
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </Modal>
    </>);
}
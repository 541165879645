import { Alert, Button, Card, Checkbox, Divider, Form, Input, Modal, Select, Switch, Typography } from "antd";
import React, { useEffect } from "react";
import { useEnvironment } from "../../../hooks/useEnvironment";
import { v4_project_addNewAddress } from "../../../routing/v4_Routes/project.routes";

const { Item: FormItem } = Form;

const accountTypeOptions = [
    { value: "Standard-Rechnungsadresse", label: "Standard-Rechnungsadresse (Primär für alle Dokumente)" },
    { value: "Rechnungsadresse", label: "Rechnungsadresse" },
    { value: "Lieferadresse", label: "Lieferadresse" },
    { value: "Abholadresse", label: "Abholadresse" },
    { value: "Projektstandort", label: "Projektstandort" },
    { value: "Baustelle", label: "Baustelle" },
    { value: "Versandlager", label: "Versandlager" },
    { value: "Werkstatt", label: "Werkstatt" },
    { value: "Sonstige Adresse", label: "Sonstige Adresse (ggf. Spezifizieren)" },
];

export function NewAddressModal({ //Adresse = Kontakt
    callback,
    callbackOnCancel,
    showAsCard = true,
    edit = false,
    address = null,
    projectId = null,
}) {

    const [visible, setVisible] = React.useState(false);
    const { project, getProject, loading: loadingProject } = useEnvironment();
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);

    const setInitialValues = async () => {
        if (projectId) {
            await getProject(projectId);
        };

        if (edit && address) {
            form.setFieldsValue({
                project: projectId,
                name: address?.name,
                addressType: address?.addressType,
                street: address?.street,
                addressLine2: address?.addressLine2,
                zip: address?.zip,
                city: address?.city,
                notes: address?.notes,
            });
        };
    };

    const onCancel = () => {
        setVisible(false);
        if (callbackOnCancel) {
            callbackOnCancel();
        };
    };

    const onSave = async () => {
        setLoading(true);
        try {
            if (!projectId) {
                throw new Error("No project ID provided");
            };
            const values = await form.validateFields();
            await v4_project_addNewAddress(projectId, values);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setVisible(false);
            if (callback) {
                callback();
            };
        };
    };

    useEffect(() => {
        if (projectId) {
            setInitialValues();
        };

    }, [projectId]);

    return (<>
        <div>
            {showAsCard ? (
                <Card>
                    <Button type="link" onClick={() => {
                        setVisible(true);
                    }}>
                        Neue Adresse erstellen
                    </Button>
                </Card>
            ) : (
                <Button type="link" onClick={() => {
                    setVisible(true);
                }}>
                    Neue Adresse erstellen
                </Button>
            )}
        </div>

        <Modal
            title={edit ? "Adresse bearbeiten" : "Neue Adresse erstellen"}
            open={visible}
            onCancel={onCancel}
            width={"60%"}
            closable={false}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Abbrechen
                </Button>,
                <Button key="submit" type="primary" onClick={onSave}>
                    Speichern
                </Button>,
            ]}
            loading={loading || loadingProject}
            destroyOnClose
        >
            <div>
            <Alert
                message="Hinweis zur Adresse"
                description={<div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                    <Typography.Text>
                        Die Adresse wird nur diesem Projekt zugeordnet. Es können mehrere Rechnungsadressen ausgewählt werden. Die Standardadresse wird jedoch auf allen Dokumenten als Rechnungsadresse verwendet. Die Standard-Rechnungsadresse kann nur einmal gesetzt werden.
                    </Typography.Text>
                    <br /><br />
                    <Typography.Text>
                        Firma/Name ist die Bezeichnung, die später auf Dokumenten wie Rechnungen oder Lieferscheinen erscheint. (z.B. BMW AG Niederlassung München).
                        Für weitere Ergänzungen, kann der Adresszusatz verwendet werden. (z.B. Werk 1)
                    </Typography.Text>
                </Typography.Paragraph>
                </div>}
                type="info"
                showIcon
                style={{ marginBottom: 15 }}
            />
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={async (values) => {
                        console.log(values);
                        setVisible(false);
                        if (callback) {
                            callback();
                        };
                    }}
                >
                    <FormItem
                        label="Firma/Name"
                        name="name"
                        rules={[{ required: false, message: "Bitte geben Sie einen Namen an." }]}
                    >
                        <Input placeholder="z.B. John Doe, Einkaufsleitung, BMW AG Niederlassung Mücnhen" />
                    </FormItem>

                    <FormItem
                        label="Adress-Typ"
                        name="addressType"
                        rules={[{ required: true, message: "Bitte geben Sie einen Account Typ an." }]}
                    >
                        <Select
                            placeholder="Account Typ"
                            options={accountTypeOptions}
                        />
                    </FormItem>

                    <FormItem
                        label="Straße & Hausnummer"
                        name="street"
                        rules={[{ required: true, message: "Bitte geben Sie einen Straße an." }]}
                    >
                        <Input placeholder="Lina-Ammon-Str. 3" />
                    </FormItem>

                    <FormItem
                        label="Adresszusatz"
                        name="addressLine2"
                    >
                        <Input placeholder="Werk 1" />
                    </FormItem>

                    <FormItem
                        label="PLZ"
                        name="zip"
                        rules={[{ required: true, message: "Bitte geben Sie eine PLZ an." }]}
                    >
                        <Input placeholder="90471" maxLength={15} />
                    </FormItem>

                    <FormItem
                        label="Stadt"
                        name="city"
                        rules={[{ required: true, message: "Bitte geben Sie eine Stadt/Ort an." }]}
                    >
                        <Input placeholder="Nürnberg" />
                    </FormItem>

                    <FormItem
                        label="Notizen"
                        name="notes"
                        rules={[{ required: false, message: "Bitte geben Sie Notizen an." }]}
                    >
                        <Input.TextArea placeholder="Notizen" />
                    </FormItem>
                </Form>
            </div>
        </Modal>

    </>)
}
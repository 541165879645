import { setAPI, v3_setAPI } from "../api";
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from "../../utils/messageServices/messageService";
import { displayInfoNotification, displaySuccessNotification } from "../../utils/messageServices/notificationService";
import { automaticUpdate_AS0, automaticUpdate_AutoSession, automaticUpdate_ProjectStateWithoutRefetch } from "../../redux/updateUtils/automaticUpdate";
import { fetchUsersV1 } from "../../modules/fetchUsers";
import { errorDisplayHelper } from "../../utils/errorHelper";
import _ from "lodash";
import { displayErrorModal } from "../../utils/messageServices/modalService";
import { axiosInstance } from "../../axios/axiosInstance";

const API_URL = v3_setAPI();

export async function user_fetchSingleProfile(mongoRef) {
    try {
        const task = await axiosInstance.post(`${API_URL}/users/user/${mongoRef}`, { uid: mongoRef })
            .then((result) => {
                displaySuccessNotification("Nutzerdaten erfolgreich abgerufen.")
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });

        automaticUpdate_AS0("user", task.user);
        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler!", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};
//Ruft die Mongo USerdaten ab
/**
 * @description This function is used to fetch all users from the database. It's used to update the redux store.
 * @returns Boolean
 * @deprecated This function is deprecated. Please use route_fetchAllUsers instead.
 */
export async function user_fetchProfiles() {
    try {
        const task = await axiosInstance.post(`${API_URL}/users/users`, { data: "" })
            .then((result) => {
                displaySuccessNotification("Nutzerdaten erfolgreich abgerufen.")
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });

        automaticUpdate_AS0("users", task.users);
        automaticUpdate_AutoSession("users", task.users);
        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Beim abrufen der Nutzerdaten", error?.response?.data?.error, error?.response?.data?.stack)

        return null;
    }
};

/**
 * @description - This function is used to fetch all users from the database. It's used to update the redux store.
 * @returns Object -> {users: Array, firebaseUsers: Array, status: Number, message: String}
 * @version 2.0
 */
export async function route_fetchAllUsers() {
    try {
        const data = await axiosInstance.post(`${API_URL}/users/users`, { data: "" })
            .then((result) => {
                // console.log(result);
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });

        automaticUpdate_AutoSession("users", data.users);

        return {
            users: data.users,
            firebaseUsers: [],
            status: data.status,
            statusText: data.statusText
        }
    } catch (error) {
        console.log(error);
        displayErrorModal("Beim abrufen der Nutzerdaten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};

//Ruft die Firebase Userdaten ab
export async function user_fetchFirUsers() {
    try {
        const task = await axiosInstance.post(`${API_URL}/users/users`, { data: "" })
        const users = await fetchUsersV1(false, false, false, false);

        automaticUpdate_AS0("users", task.users);
        automaticUpdate_AS0("firebaseUsers", users);
        automaticUpdate_AutoSession("users", task.users);
        automaticUpdate_AutoSession("firebaseUsers", users);
        // displaySuccessNotification("Nutzerdaten erfolgreich abgerufen.")
        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Nutzerdaten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

export async function statistic_fetchAll(filter, projectid) {
    try {
        const body = { filter: filter, project: projectid };
        console.log("Filter: ", body);
        const task = await axiosInstance.post(`${API_URL}/statistics/calculateStatistics`, body)
            .then((result) => {
                console.log(result);
                return result.data
            })
            .catch((err) => {
                return { errorsAndWarnings: err };
            });

        // automaticUpdate_AS0("statistics", task);
        automaticUpdate_AutoSession("statistics_main", task);
        errorDisplayHelper(task.errorsAndWarnings);
        return task;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Statistikdaten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

export async function statistic_contractCosts(filter, project) {
    try {
        const task = await axiosInstance.post(`${API_URL}/statistics/calculateContractCosts`, { filter: filter, project: project._id })
            .then((result) => {
                displaySuccessNotification("Statistikdaten erfolgreich abgerufen.")
                console.log(result);
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });

        // automaticUpdate_AS0("statistics", task);
        automaticUpdate_AutoSession("statistics_contracts", task);

        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Statistikdaten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

export async function statistic_projectTime(filter, project) {
    try {
        const task = await axiosInstance.post(`${API_URL}/statistics/calculateProjectTime`, { filter: filter, project: project._id })
            .then((result) => {
                displaySuccessNotification("Statistikdaten erfolgreich abgerufen.")
                console.log(result);
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });

        // automaticUpdate_AS0("statistics", task);
        automaticUpdate_AutoSession("statistics_projectTime", task);
        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Statistikdaten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

export async function costs_fetchAll() {
    try {
        const task = await axiosInstance.post(`${API_URL}/costs/get`, { data: "" })
            .then((result) => {
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                displayErrorMessage("Fehler!", "Beim abrufen der Kostendaten ist ein Fehler aufgetreten.")
                throw new Error(err);
            });

        automaticUpdate_AS0("costs", task);
        // automaticUpdate_AutoSession("costs", task.costs);
        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Kostendaten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

export async function costs_fetchAllByProject(_id) {
    try {
        const task = await axiosInstance.post(`${API_URL}/costs/get/${_id}`, { _id: _id })
            .then((result) => {
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                displayErrorMessage("Fehler!", "Beim abrufen der Kostendaten ist ein Fehler aufgetreten.")
                throw new Error(err);
            });

        automaticUpdate_AS0("costs", task);
        // automaticUpdate_AutoSession("costs", task.costs);
        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Kostendaten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

export async function costs_fetchSingle(cost_id) {
    try {
        const task = await axiosInstance.post(`${API_URL}/costs/getbyid`, { cost_id: cost_id })
            .then((result) => {
                displaySuccessNotification("Kostendaten erfolgreich abgerufen.")
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });

        automaticUpdate_AS0("selectedCost", task);
        // automaticUpdate_AutoSession("costs", task.costs);
        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Kostendaten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

/**
 * 
 * @param {Object} cost 
 * @param {ObjectId} _id - Project ID
 * @param {String} query 
 * @returns 
 */
export async function costs_create(cost, _id, query) {
    try {
        const task = await axiosInstance.post(`${API_URL}/costs/add/${_id}?${query}`, { ...cost })
            .then((result) => {
                displaySuccessNotification("Kostensatz erfolgreich erstellt.")
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });

        automaticUpdate_AS0("selectedCost", task);
        // automaticUpdate_AutoSession("costs", task.costs);
        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Kostendaten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

export async function costs_update(cost_id, fieldName, fieldValue) {
    try {
        await axiosInstance.post(`${API_URL}/costs/update`, { cost_id: cost_id, update: { fieldName: fieldName, fieldValue: fieldValue } })
            .then((result) => {
                displaySuccessNotification("Kostendaten erfolgreich geändert.")
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });

        // automaticUpdate_AS0("costs", task);
        // automaticUpdate_AutoSession("costs", task.costs);
        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Kostendaten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

export async function costs_delete(cost_id) {
    try {
        await axiosInstance.post(`${API_URL}/costs/delete`, { cost_id: cost_id })
            .then((result) => {
                displaySuccessNotification("Kostendaten erfolgreich gelöscht.")
                console.log(result.data);
                displaySuccessMessage("Serverantwort: ", result.data.message);
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });

        // automaticUpdate_AS0("costs", task);
        // automaticUpdate_AutoSession("costs", task.costs);
        return true;
    } catch (error) {
        console.log(error);
       displayErrorModal("Fehler beim löschen der Kostendaten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

export async function costs_deactivate(cost_id, deactivateDate) {
    try {
        await axiosInstance.post(`${API_URL}/costs/deactivate`, { cost_id: cost_id, deactivateDate: deactivateDate })
            .then((result) => {
                console.log(result.data);
                displaySuccessNotification("Kostensatz erfolgreich deaktiviert. Eine Reaktivierung ist nicht möglich.");
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });

        // automaticUpdate_AS0("costs", task);
        // automaticUpdate_AutoSession("costs", task.costs);
        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim deaktivieren der Kostendaten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};


export async function route_upgadeTaskVersion(project_id) {
    try {
        await axiosInstance.post(`${API_URL}/article/transfer/${project_id}`, { data: null })
            .then((result) => {
                displayInfoNotification("Aufgabe erfolgreich erhalten.")
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });
        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Ändern der Aufgabenversion", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

/**
 * 
 * @param {ObjectId} project_id - required
 * @param {Boolean} allDocs  - optional
 * @param {String} searchValue - optional
 * @returns 
 * @description - This function is used to read all articles from the database. Normally with Datasets wich counts is less than 50, it will be read all. If the count is higher than 50, it's neccessary to provide at least a searchValue. Or you can set allDocs to true, to read all articles wich loads all..
 */
export async function route_readArticles(project_id, allDocs, searchValue) {
    if (allDocs === undefined) {
        allDocs = false;
    };

    if (searchValue === undefined) {
        searchValue = "";
    };

    try {
        const data = await axiosInstance.post(`${API_URL}/article/crud/read`, { project_id: project_id, allDocs: allDocs, searchValue: searchValue })
            .then((result) => {
                displayInfoNotification("Artikel erfolgreich abgerufen.")
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });

        automaticUpdate_ProjectStateWithoutRefetch("selectedProjectArticles", data);
        return true;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Artikel", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

/**
 * 
 * @param {ObjectId} project_id - required
 * @param {Boolean} allDocs  - optional
 * @param {String} searchValue - optional
 * @returns 
 * @description - This function is used to read all articles from the database. Normally with Datasets wich counts is less than 50, it will be read all. If the count is higher than 50, it's neccessary to provide at least a searchValue. Or you can set allDocs to true, to read all articles wich loads all..
 */
export async function route_searchArticle(project_id, allDocs, searchValue) {
    if (allDocs === undefined) {
        allDocs = false;
    };

    if (searchValue === undefined) {
        searchValue = "";
    };

    try {
        const data = await axiosInstance.post(`${API_URL}/article/crud/read`, { project_id: project_id, allDocs: allDocs, searchValue: searchValue })
            .then((result) => {
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                throw new Error(err);
            });
        return data;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Artikel", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    }
};

/**
 * 
 * @param {ObjectId} _id - required
 * @param {*} fieldValue - required
 * @param {String} fieldName  - required
 * @param {String} expectedFieldType - required
 * @param {Boolean} largeSet - default false | optional
 * @param {Boolean} retrospective - default false | optional
 * @returns 
 * @see WARNING: This function is not complete yet. It's only a prototype.
 * @name route_updateArticle
 */
export async function route_updateArticle(_id, fieldValue, fieldName, expectedFieldType, largeSet, retrospective) {
    let finalData = null;
    try {
        if (retrospective === undefined) {
            retrospective = false;
        };

        if (largeSet === undefined) {
            largeSet = false;
        };

        if (fieldName === undefined) {
            displayErrorMessage("Fehlerhafte Anfrage", "Es ist ein technischer Fehler aufgetreten. Bitte kontaktiere den Support.")
            throw new Error("fieldName is undefined");
        };

        if (fieldValue === undefined) {
            displayErrorMessage("Fehlerhafte Eingabe", "Es wurde kein Wert angegeben. Bitte versuche es erneut.")
        };

        if (_id === undefined) {
            displayErrorMessage("Fehlerhafte Anfrage", "Es ist ein technischer Fehler aufgetreten. Bitte kontaktiere den Support.")
            throw new Error("_id is undefined");
        };

        if (expectedFieldType === undefined) {
            displayErrorMessage("Fehlerhafte Anfrage", "Es ist ein technischer Fehler aufgetreten. Bitte kontaktiere den Support.")
            throw new Error("expectedFieldType is undefined");
        };

        const data = await axiosInstance.post(`${API_URL}/article/crud/update`, { _id: _id, fieldValue: encodeURIComponent(fieldValue), fieldName: fieldName, retrospective: retrospective, expectedFieldType: expectedFieldType })
            .then((result) => {
                displayInfoNotification("Artikel erfolgreich abgerufen.")
                console.log(result.data);
                finalData = result.data;
                return result.data
            })
            .catch((err) => {
                displayErrorModal("Fehler beim Laden der Artikel", err?.response?.data?.error, err?.response?.data?.stack)
                throw new Error(err);
            });

        //Hier fehlt noch die Funktion zum updaten des aktuell ausgewählten Artikels
        //automaticUpdate_ProjectStateWithoutRefetch("selectedProjectArticles", data);
        return data;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Artikel", error?.message, error?.stack)
        return null;
    } finally {
        if (largeSet === false) {
            route_readArticles(_.isObject(finalData.project) ? finalData.project._id : finalData.project);
        } else {
            route_readArticles(_.isObject(finalData.project) ? finalData.project._id : finalData.project, true, finalData.title);
        };
    };
};

/**
 * 
 * @param {ObjectId} project_id - required
 * @param {Object} fromData  - optional
 * @returns 
 * @description - This function is used to create a new article in the database.
 */
export async function route_createArticle(formData) {
    if (formData === undefined) {
        displayWarningMessage("Fehler!", "Es wurden keine Daten übermittelt. Bitte versuche es erneut.")
        return null;
    }

    try {
        const data = await axiosInstance.post(`${API_URL}/article/crud/create`, formData)
            .then((result) => {
                displaySuccessNotification("Artikel erfolgreich erstellt.")
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                displayErrorModal("Fehler beim erstellen des Artikels", err?.response?.data?.error, err?.response?.data?.stack)
                throw new Error(err);
            });

        //automaticUpdate_ProjectStateWithoutRefetch("selectedProjectArticles", data);
        return data;
    } catch (error) {
        console.log(error.message);
        displayErrorModal("Fehler beim erstellen des Artikels", error?.message, error?.stack)
        return null;
    }
};


/**
* 
* @param {ObjectId} article_id - required
* @param {ObjectId} project_id  - required
* @param {Boolean} removeReferences  - optional | default false
* @returns 
* @description - This function is used to create a new article in the database.
*/
export async function route_deleteArticle(article_id, project_id) {
    if (article_id === undefined || project_id === undefined) {
        console.log("article_id: ", article_id);
        console.log("project_id: ", project_id);
        displayWarningMessage("Fehler!", "Es wurden keine Daten übermittelt. Bitte versuche es erneut.")
        return null;
    }

    try {                 //Hier kann je nach anforderung das löschen der referenzen deaktiviert werden
        const data = await axiosInstance.post(`${API_URL}/article/crud/delete`, { article_id: article_id, project_id: project_id })
            .then((result) => {
                displaySuccessNotification("Artikel und alle zugehörigen referenzen gelöscht.")
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                displayErrorModal("Fehler beim löschen des Artikels", err?.response?.data?.error, err?.response?.data?.stack)
                throw new Error(err);
            });

        //automaticUpdate_ProjectStateWithoutRefetch("selectedProjectArticles", data);
        return data;
    } catch (error) {
        console.log(error.message);
        displayErrorModal("Fehler beim löschen des Artikels", error?.message, error?.stack)
        return null;
    }
};

/**
 * @description - This function is useed to sync userData in backend, but dont delivers any data to the frontend.
 */
export async function route_syncUserDataHardSync(mongoRef) {
    try {
        if (mongoRef === undefined) {
            return null;
        };
        const data = await axiosInstance.post(`${API_URL}/users/sync/single`, { user: { _id: mongoRef } })
            .then(async () => {
                return true;
            })
            .catch((err) => {
                displayErrorModal("Fehler beim synchronisieren der Nutzerdaten", err?.response?.data?.error, err?.response?.data?.stack)
                throw new Error(err);
            });

        return data;
    } catch (error) {
        console.log(error.message);
        displayErrorModal("Fehler beim synchronisieren der Nutzerdaten", error?.message, error?.stack)
        return null;
    }
};

/**
 * @description This function is used to manage an contract in the database.
 */
export async function route_manageContract(formData, action, uid) {
    if (formData === undefined) {
        displayWarningMessage("Fehler! 😱", "Es wurden keine Daten übermittelt. Bitte versuche es erneut.")
        return null;
    };

    if (uid === undefined) {
        displayWarningMessage("Fehler! 😱", "Es wurde kein Nutzer ausgewählt. Bitte versuche es erneut.")
        return null;
    };

    try {
        const data = await axiosInstance.post(`${API_URL}/contract/user/${uid}?action=${action}`, formData)
            .then((result) => {
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                displayErrorModal("Fehler beim verwalten des Vertrags", err?.response?.data?.error, err?.response?.data?.stack)
                throw new Error(err);
            });

        return data;
    } catch (error) {
        console.log(error.message);
        displayErrorModal("Fehler beim verwalten des Vertrags", error?.message, error?.stack)
        return null;
    }
};

/**
 * @description This function is used to update a customField
 * @warning Uses the old API
 */
export async function route_updateCustomField(updateData, fieldId, projectId) {
    if (updateData === undefined) {
        displayWarningMessage("Fehler! 😱", "Es wurden keine Daten übermittelt. Bitte versuche es erneut.")
        return null;
    };

    if (fieldId === undefined || projectId === undefined) {
        displayWarningMessage("Fehler! 😱", "Es wurden keine Parameter übergeben. Bitte versuche es erneut.")
        return null;
    };

    const API_V1 = setAPI();

    try {
        const data = await axiosInstance.post(`${API_V1}/api/db/schema/projects/${projectId}/schemas/${fieldId}/update`, updateData)
            .then((result) => {
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                displayErrorModal("Fehler beim verwalten des Vertrags", err?.response?.data?.error, err?.response?.data?.stack)
                throw new Error(err);
            });

        return data;
    } catch (error) {
        console.log(error.message);
        displayErrorModal("Fehler beim verwalten des Vertrags", error?.message, error?.stack)
        return null;
    }
};

/**
* 
* @param {ObjectId} userId - required
* @returns 
* @description - This function is used to create a new article in the database.
*/
export async function route_getAbsences(user_id) {
    if (user_id === undefined) {
        displayWarningMessage("Fehler! 😱", "Es wurden keine Daten übermittelt. Bitte versuche es erneut.")
        return null;
    }

    try {                 //Hier kann je nach anforderung das löschen der referenzen deaktiviert werden
        const data = await axiosInstance.post(`${API_URL}/times/user/${user_id}`, { user_id: user_id })
            .then((result) => {
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                displayErrorModal("Fehler beim laden der Abwesenheiten", err?.response?.data?.error, err?.response?.data?.stack)
                throw new Error(err);
            });

        return data;
    } catch (error) {
        console.log(error.message);
        displayErrorModal("Fehler beim laden der Abwesenheiten", error?.message, error?.stack)
        return [];
    }
};


/**
* 
* @param {ObjectId} userId - required
* @returns 
* @description - This function is used to create a new article in the database.
*/
export async function route_getUserProjects(user_id) {
    if (user_id === undefined) {
        displayWarningMessage("Es ist ein Fehler aufgetreten...😱", "Wir haben eine ungültige Anfrage erhalten. Bitte versuche es erneut. Sollte der Fehler weiterhin bestehen, kontaktiere bitte den Support.")
        return null;
    };

    try {
        const data = await axiosInstance.post(`${API_URL}/users/user/cost_share/${user_id}/get/projects`, { configuration: null })
            .then((result) => {
                console.log(result.data);
                return result.data
            })
            .catch((err) => {
                displayErrorModal("Es ist ein Fehler aufgetreten...😱", err?.response?.data?.error, err?.response?.data?.stack)
                throw new Error(err);
            });

        return data;
    } catch (error) {
        console.log(error.message);
        displayErrorModal("Es ist ein Fehler aufgetreten...😱", error?.message, error?.stack)
        return [];
    }
};


/**
* 
* @param {ObjectId} userId - required
* @returns 
* @description - This function is used to create a new article in the database.
*/
export async function route_saveUserCostShare(user_id, shareDetails) {
    if (user_id === undefined) {
        displayWarningMessage("Es ist ein Fehler aufgetreten...😱", "Wir haben eine ungültige Anfrage erhalten. Bitte versuche es erneut. Sollte der Fehler weiterhin bestehen, kontaktiere bitte den Support.")
        return;
    };

    try {
        const data = await axiosInstance.post(`${API_URL}/users/user/cost_share/${user_id}/set`, { configuration: shareDetails })
            .then((result) => {
                console.log(result.data);
                displaySuccessNotification("Kostenteilung erfolgreich gespeichert. Es kann bis zu 5 Minuten dauern, bis die Änderungen sichtbar sind.")
                return result.data
            })
            .catch((err) => {
                displayErrorModal("Es ist ein Fehler bei der bearbeitung der Kostenverteilung aufgetreten.", err?.response?.data?.error, err?.response?.data?.stack)
                throw new Error(err);
            });

        return data;
    } catch (error) {
        console.log(error.message);
        displayErrorModal("Es ist ein Fehler bei der bearbeitung der Kostenverteilung aufgetreten.", error?.message, error?.stack)
        return [];
    }
};
import { v4_setAPI } from "../api";
import _ from "lodash";
import { axiosInstance } from "../../axios/axiosInstance";
import { displayErrorModal } from "../../utils/messageServices/modalService";

const API_URL = v4_setAPI();


export async function v4_get_downtime_data({ project, start, end, search, page = 1, limit = 10 }) {
    try {
        const response = await axiosInstance.get(`${API_URL}/downtime/data`, {
            params: {
                project,
                start,
                end,
                search,
                page,
                limit
            }
        });

        console.log(response);
        return response.data;

    } catch (error) {
        console.error(error);
        displayErrorModal(
            "Fehler beim Laden der Downtime-Daten",
            error?.response?.data?.error,
            error?.response?.data?.stack
        );
        return null;
    }
};



export async function v4_create_downtime(downtimeData) {
    try {
        const response = await axiosInstance.post(`${API_URL}/downtime`, downtimeData);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal(
            "Fehler beim Erstellen des Downtime-Eintrags",
            error?.response?.data?.error,
            error?.response?.data?.stack
        );
        return null;
    }
}

export async function v4_update_downtime(id, downtimeData) {
    try {
        const response = await axiosInstance.put(`${API_URL}/downtime/${id}`, downtimeData);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal(
            "Fehler beim Aktualisieren des Downtime-Eintrags",
            error?.response?.data?.error,
            error?.response?.data?.stack
        );
        return null;
    }
}

export async function v4_delete_downtime(id) {
    try {
        const response = await axiosInstance.delete(`${API_URL}/downtime/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal(
            "Fehler beim Löschen des Downtime-Eintrags",
            error?.response?.data?.error,
            error?.response?.data?.stack
        );
        return null;
    }
}
import { Button, Card, Checkbox, DatePicker, Drawer, Form, Input, Modal, Popconfirm, Select, Typography } from "antd";
import React, { useEffect } from "react";
import { v4_completeApproval } from "../../../routing/v4_Routes/order.routes";
import _ from "lodash";
import datePickerLocale from "../../../locale/datePickerLocale.json";
import dayjs from "dayjs";


export function OrderApprovalModal({
    showAsCard,
    buttonDisabled,
    callback,
    order,
}) {
    const [open, setOpen] = React.useState(false);
    const [approved, setApproved] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [form] = Form.useForm();

    const onSubmit = async () => {
        setLoading(true);
        try {
            console.log("OrderApprovalModal -> order", order);
            if (!order) throw new Error("Es wurde keine Bestellung ausgewählt.");
            if (!order.approval?._id) throw new Error("Es wurde keine Freigabe für diese Bestellung gefunden.");
            const values = await form.validateFields();
            console.log("OrderApprovalModal -> values", values);
            await v4_completeApproval(order?._id, order.approval?._id, approved, values.comment, values.confirmedDeliveryDate);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            onCancel();
        };
    };

    const onCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (approved) {
            form.setFieldsValue({
                confirmedDeliveryDate: dayjs(order?.requestedDeliveryDate)
            });
        } else {
            form.setFieldsValue({
                confirmedDeliveryDate: null
            });
        };
    }, [approved]);

    useEffect(() => {

        return () => { //Handled, when the component is unmounted (closed)
            if (callback && typeof callback === "function") {
                if (open) {
                    callback();
                };
            };
        };
    }, [open]);

    return (
        <div>
            <div>
                {showAsCard ? (<>
                    <Card
                        style={{ minWidth: 275, alignContent: "center", textAlign: "center" }}
                    >
                        <Button
                            onClick={() => setOpen(true)}
                            type="link"
                            disabled={buttonDisabled}
                            loading={loading}
                        >
                            Auftrag Bestätigen/Ablehnen
                        </Button>
                    </Card>
                </>) : (<>
                    <Button
                        onClick={() => setOpen(true)}
                        type="primary"
                        disabled={buttonDisabled}
                        loading={loading}
                    >
                        Auftrag Bestätigen/Ablehnen
                    </Button>
                </>)}
            </div>

            <Modal
                title={`Auftragsbestätigung ${order?.orderNumber ?? ""}`}
                open={open}
                onCancel={onCancel}
                destroyOnClose
                width={"60%"}
                centered
                loading={loading}
                footer={[
                    <Popconfirm
                        key="submit"
                        onConfirm={() => onSubmit()}
                        onCancel={onCancel}
                        okText="Freigeben"
                        cancelText="Freigabe abberechen"
                        title={<Typography.Text>
                            Sind Sie sicher, dass Sie den Auftrag freigeben möchten? Diese Aktion kann nicht rückgängig gemacht werden. <br />Es wird AUTOMATISCH eine Auftragsbestätigung an den Kunden gesendet.
                        </Typography.Text>}
                    >
                        <Button disabled={!approved} type="primary">Freigeben</Button>
                    </Popconfirm>,
                    <Popconfirm
                        key="abort"
                        type="primary"
                        onConfirm={() => onSubmit()}
                        onCancel={onCancel}
                        okText="Ablehnen"
                        cancelText="Ablehnung abberechen"
                        okType="danger"
                        title={<Typography.Text>
                            Sind Sie sicher, dass Sie den Auftrag ablehnen möchten? Diese Aktion kann nicht rückgängig gemacht werden. <br />Es wird NICHT automatisch eine Ablehnung an den Kunden gesendet
                        </Typography.Text>}
                    >
                        <Button disabled={approved} danger>Ablehnen</Button>
                    </Popconfirm>,
                    <Button
                        key="cancel"
                        onClick={() => setOpen(false)}
                    >
                        Schließen
                    </Button>
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        comment: "",
                        approved: false
                    }}
                >
                    <Form.Item
                        label="Freigabe"
                        name="approved"
                        valuePropName="checked"
                    >
                        <Checkbox
                            onChange={(e) => setApproved(e.target.checked)}
                        >Freigabe (Wenn angewählt dann Freigabe: Ja)</Checkbox>
                    </Form.Item>
                    {approved && (<Form.Item
                        label="Bestätigtes Lieferdatum"
                        name="confirmedDeliveryDate"
                        tooltip="Das zu bestätigende Lieferdatum"
                        rules={[{ required: true, message: 'Bitte gebe das Lieferdatum ein!' }]}
                    >
                        <DatePicker
                            locale={datePickerLocale}
                            format={"DD.MM.YYYY"}
                            showHour
                            showMinute
                        />
                    </Form.Item>)}
                    {approved ? (<Form.Item
                        label="Kommentar"
                        name="comment"
                    >
                        <Input.TextArea />
                    </Form.Item>) : <Form.Item
                        label="Kommentar"
                        name="comment"
                        required={[{ required: true, message: "Bitte geben Sie einen Kommentar ein" }]}
                    >
                        <Select
                            placeholder="Bitte wählen Sie einen Grund aus"
                        >
                            <Select.Option value="keine Bearbeitung möglich - Fehlteile">
                                keine Bearbeitung möglich - Fehlteile
                            </Select.Option>
                            <Select.Option value="keine Bearbeitung möglich - Liefertermin">
                                keine Bearbeitung möglich - Liefertermin
                            </Select.Option>
                        </Select>
                    </Form.Item>}
                </Form>
            </Modal>
        </div>
    );
};
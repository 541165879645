import { Descriptions } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

function UserProfileContractDetailsComponent() {
    // eslint-disable-next-line
    const { uid } = useParams();
    const user = useSelector((state) => state.userState.mongoUser);
    const firId = useSelector((state) => state.userState.firId);
    const [contract, setContract] = React.useState(null);

    useEffect(() => {
        const getContract = () => {
            if (_.has(user, "contract")) {
                user.contract !== null || undefined ? setContract(user.contract) : setContract(null);
            };
        };

        return getContract();
    }, [user]);

    return (
        <>
            <div>
            <Descriptions title={`Vertragsdaten zu ${user.firstName} ${user.lastName}`} bordered>
                        <Descriptions.Item label="Eintritt">
                            {contract !== null || undefined ? contract.startDate : "Keine Daten vorhanden"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Austritt">
                            {contract !== null || undefined ? contract.endDate : "Keine Daten vorhanden"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Vertragsart">
                            {contract !== null || undefined ? contract.contractType : "Keine Daten vorhanden"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Vertragsstatus">
                            {contract !== null || undefined ? contract.contractStatus : "Keine Daten vorhanden"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Vertragslaufzeit">
                            {contract !== null || undefined ? contract.contractType === "unbefristet" ? "unbefristed" : "befristet" : "Keine Daten vorhanden"}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions title={`Zeitdaten zu ${user.firstName} ${user.lastName}`} bordered
                        style={{ marginTop: 20, marginBottom: 20 }}
                    >
                        <Descriptions.Item label="Arbeitszeit">
                            {contract !== null || undefined ? contract.contractDetails.workTime.monthlyWorkTime : "Keine Daten vorhanden"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Urlaubstage">
                            {contract !== null || undefined ? contract.contractDetails.vacation : "Keine Daten vorhanden"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tägl. Arbeitszeit - ca. Angabe | netto">
                            {contract !== null || undefined ? Number(contract.contractDetails.workTime.monthlyWorkTime) / 4 / 5 : "Keine Daten vorhanden"}
                        </Descriptions.Item>
                    </Descriptions>
            </div>
        </>
    );
};

export default UserProfileContractDetailsComponent;
export const incotermsCollection = [
    { key: "EXW", value: "EXW", text: "EXW", description: "Ex Works" },
    { key: "FCA", value: "FCA", text: "FCA", description: "Free Carrier" },
    { key: "CPT", value: "CPT", text: "CPT", description: "Carriage Paid To" },
    { key: "CIP", value: "CIP", text: "CIP", description: "Carriage and Insurance Paid To" },
    { key: "DAP", value: "DAP", text: "DAP", description: "Delivered At Place" },
    { key: "DPU", value: "DPU", text: "DPU", description: "Delivered At Place Unloaded" },
    { key: "DDP", value: "DDP", text: "DDP", description: "Delivered Duty Paid" },
    { key: "FAS", value: "FAS", text: "FAS", description: "Free Alongside Ship" },
    { key: "FOB", value: "FOB", text: "FOB", description: "Free On Board" },
    { key: "CFR", value: "CFR", text: "CFR", description: "Cost and Freight" },
    { key: "CIF", value: "CIF", text: "CIF", description: "Cost, Insurance and Freight" },
];

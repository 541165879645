import React from 'react';
import { Route } from 'react-router-dom';
import { MainProject } from "../../components/pages/project/MainProject.page";
import { ProjectSettingsDetailPage } from '../../components/pages/project/ProjectSettingsDetail.page';

const projectAdminPages = [
    <Route path="/p/:_id/details/settings" element={<MainProject />} />,
    <Route path="/p/:_id/details/setting/advanced" element={<ProjectSettingsDetailPage />} />
];

export default projectAdminPages;


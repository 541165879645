import { Tag } from "antd";

export const parseOrderStatus = (status, showAsTag = true) => {
    //Possible: ['new', 'rejected', 'inProgress', 'completed', 'delivered', 'invoiced', 'cancelled'],
    if (showAsTag) {
        switch (status) {
            case 'new':
                return <Tag color="blue">Neu</Tag>;
            case 'inApproval':
                return <Tag color="cyan">Warten auf AB</Tag>;
            case 'rejected':
                return <Tag color="red">Abgelehnt</Tag>;
            case 'inProgress':
                return <Tag color="orange">In Bearbeitung</Tag>;
            case 'completed':
                return <Tag color="green">Abgeschlossen</Tag>;
            case 'delivered':
                return <Tag color="green">Geliefert</Tag>;
            case 'invoiced':
                return <Tag color="green">In Rechnung gestellt</Tag>;
            case 'cancelled':
                return <Tag color="red">Storniert</Tag>;
            case 'partiallyDelivered':
                return <Tag color="orange">Teilweise geliefert</Tag>;
            case "confirmed":
                return <Tag color="blue">Auftrag bestätigt</Tag>;
            default:
                return <Tag color="grey">Unbekannt</Tag>;
        }
    } else {
        switch (status) {
            case 'new':
                return 'Neu';
            case 'inApproval':
                return 'Warten auf Auftragsbestätigung';
            case 'rejected':
                return 'Abgelehnt';
            case 'inProgress':
                return 'In Bearbeitung';
            case 'completed':
                return 'Abgeschlossen';
            case 'delivered':
                return 'Geliefert';
            case 'invoiced':
                return 'In Rechnung gestellt';
            case 'cancelled':
                return 'Storniert';
            case "confirmed":
                return "Auftrag bestätigt (nicht gestartet)"
            case 'partiallyDelivered':
                return 'Teilweise geliefert';
            default:
                return 'Unbekannt';
        };
    }
};
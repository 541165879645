import { setAPI } from "./api";
import _ from "lodash";
import { displayErrorMessage, displaySuccessMessage } from "../utils/messageServices/messageService";
import { axiosInstance as axios } from "../axios/axiosInstance";

//API
const API_URL = setAPI();

export async function createUser(newUser) {
  try {
    const task = await axios.post(API_URL + "/register-login/newuser", newUser);
    if (task.status === 201) {
      displaySuccessMessage("Aktion erfolgreich!", task.data.message);
      return true;
    }
  } catch (error) {
    displayErrorMessage("Aktion fehlgeschlagen!", error.response.data.message);
    console.log(error.response.data.message);
    return null;
  }
};

export async function resetPassword(firId) {
  return await axios.post(API_URL + "/register-login/reset-password", { firId: firId });
};

export async function fetchProfile(mongoRef) {
  return axios.post(`${API_URL}/api/db/users/${mongoRef}`, { data: "" });
};

export async function fetchAllUsersWith(firebaseId) {
  return axios.post(`${API_URL}/v1/admin_api/all_users/${firebaseId}`, { data: "" });
};

export async function createContract(contract, uid, query) {

  if (_.isEmpty(query)) {

    return axios.post(`${API_URL}/api/users/v2/contracts/${uid}`, { contractData: contract });
  } else {

    return axios.post(`${API_URL}/api/users/v2/contracts/${uid}?${query}`, { contractData: contract });
  }
};